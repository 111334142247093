import {
  DownloadOutlined,
  UploadOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { Button, Checkbox, Steps, Table, Typography, Upload } from 'antd'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { read, set_cptable, utils } from 'xlsx'
import * as cp_table from 'xlsx/dist/cpexcel.full.mjs'

import type { RcFile } from 'antd/lib/upload'
import toast from 'react-hot-toast'
import CompletionLayout from '~/components/layout/CompletionLayout'
import { useAlphaStore } from '~/context'
import isJapanese from '~/utils/isJapanese'
import { getValIfNum } from '~/utils/number'
import { filterNotNullTestResAndAddToArr } from '~/utils/test-result'
import { getValidPositiveNumberFromExcelFile } from '~/utils/validations'
import { Dashboard } from '../layout/Dashboard'
import { testResultTableColumns } from './test-result/utils/constants'

const { Step } = Steps
const { Text } = Typography

set_cptable(cp_table)

const downloadTemplate = (isUsingJp: boolean) => {
  if (isUsingJp) {
    window.open(
      '/upload_sample/ALPHAアップロード用ファイル-測定結果.xlsx',
      '_blank',
    )
  } else {
    window.open(
      '/upload_sample/ALPHA upload excel of Physical Fitness Test.xlsx',
      '_blank',
    )
  }
}

/**
 * Path: test_result_upload
 */
const TestResultUpload = () => {
  const { school } = useAlphaStore()
  const { t, i18n } = useTranslation()

  const [disabled, setDisabled] = useState(true)
  const [uploadFile, setUploadFile] = useState<RcFile | undefined | null>()
  const [uploading, setUploading] = useState(false)

  const [data, setData] = useState<
    Record<string, number | undefined>[] | undefined
  >()
  const [postData, setPostData] = useState<
    Record<string, number | object>[] | undefined
  >()
  const [registerComplete, setRegisterComplete] = useState(false)
  const [currentStepIdx, setCurrentStepIdx] = useState(0)
  const [confirmed1, setConfirmed1] = useState(false)
  const [confirmed2, setConfirmed2] = useState(false)
  const [confirmed3, setConfirmed3] = useState(false)

  const history = useHistory()

  const isUsingJp = isJapanese(i18n)

  useEffect(() => {
    switch (currentStepIdx) {
      case 0:
        setDisabled(false)
        break
      case 1:
        setDisabled(!confirmed1)
        break
      case 2:
        setDisabled(!confirmed2)
        break
      case 3:
        setDisabled(!confirmed3)
        break
    }
  }, [currentStepIdx, confirmed1, confirmed2, confirmed3])

  const onFinish = async () => {
    if (!school) {
      toast.error(`${t('エラーが発生しました。')} [school is not found]`)
      console.error('TestResultUpload - onFinish, error: school is not found')
      return
    }

    setUploading(true)

    // upload backup file
    const schoolId = school._id
    const schoolName = school.attributes.schoolName

    /**
     * Determine whether when this file is uploaded.
     */
    let fileId = new Date().toISOString().split('T')[0]
    fileId = `test_result/${schoolName}_${schoolId}_${fileId}`

    let fileName: string

    const testResultFormData = new FormData()
    testResultFormData.append('file', uploadFile as Blob)
    testResultFormData.append('filename', fileId)

    await axios
      .post(
        `${process.env.REACT_APP_REST_API_URL}/alpha/file-upload`,
        testResultFormData,
      )
      .then((res) => {
        fileName = res.data.data
        // keep this log
        console.log('File uploaded successfully, file name:', fileName)
      })

    // upload test_result data
    try {
      await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_REST_API_URL}/alpha/v1/school/upload-results`,
        data: postData,
      })

      toast.success(t('アップロードしました。'))
      // form.resetFields()
      setUploadFile(null)
      setUploading(false)
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    } catch (err: any) {
      if (err.response?.data?.error === 'STUDENT_NOT_FOUND') {
        toast.error(
          `${t('エラーが発生しました。')} ${t('生徒が見つかりません。')}`,
        )
      } else {
        toast.error(`${t('エラーが発生しました。')}`)
      }

      setUploading(false)
    }
  }

  const register = async () => {
    await onFinish()
    setRegisterComplete(true)
  }

  const next = async () => {
    const nextStepIdx = currentStepIdx + 1
    if (nextStepIdx === 3 && !uploadFile) {
      toast.error(t('Excelファイルを選んでください。'))
      return
    }

    setCurrentStepIdx(nextStepIdx)
  }

  const prev = () => {
    setCurrentStepIdx(currentStepIdx - 1)
  }

  const parseXlsx = async (file: RcFile) => {
    const _data: Record<string, number | undefined>[] = []
    const submitData: Record<string, number | object>[] = []
    const data = read(await file.arrayBuffer())

    const firstSheetName = data.SheetNames[0]
    const workBook = data.Sheets

    if (firstSheetName && workBook) {
      const range = utils.decode_range(
        workBook[firstSheetName]['!ref'] as string,
      )
      range.s.r = 1 // skip row 0
      workBook[firstSheetName]['!ref'] = utils.encode_range(range)

      const rows = utils.sheet_to_json(workBook[firstSheetName]) as Record<
        string,
        string | number | object
      >[][]
      let errRowIdsString = ''

      let gradeKey: string
      let classKey: string
      let studentNumberKey: string
      let heightKey: string
      let weightKey: string
      let gridRight1Key: string
      let gridRight2Key: string
      let gridLeft1Key: string
      let gridLeft2Key: string
      let situpKey: string
      let trunkFlexion1: string
      let trunkFlexion2: string
      let sideStep1Key: string
      let sideStep2Key: string
      let shuttleRunKey: string
      let sprintRun50mKey: string
      let standingJump1Key: string
      let standingJump2Key: string
      let ballThrown1Key: string
      let ballThrown2Key: string
      let enduranceRunMinutesKey: string
      let enduranceRunSecondsKey: string

      if (isUsingJp) {
        gradeKey = '学年'
        classKey = '組'
        studentNumberKey = '出席番号'

        heightKey = '身長'
        weightKey = '体重'

        gridRight1Key = '握力右1'
        gridRight2Key = '握力右2'
        gridLeft1Key = '握力左1'
        gridLeft2Key = '握力左2'

        situpKey = '上体起こし'
        trunkFlexion1 = '長座体前屈1'
        trunkFlexion2 = '長座体前屈2'

        sideStep1Key = '反復横跳び1'
        sideStep2Key = '反復横跳び2'

        shuttleRunKey = '20mシャトルラン'
        sprintRun50mKey = '50m走'

        standingJump1Key = '立ち幅跳び1'
        standingJump2Key = '立ち幅跳び2'

        ballThrown1Key = 'ボール投げ1'
        ballThrown2Key = 'ボール投げ2'

        enduranceRunMinutesKey = '持久走（分）'
        enduranceRunSecondsKey = '持久走（秒）'
      } else {
        gradeKey = 'Grade'
        classKey = 'Class'
        studentNumberKey = 'Number'

        heightKey = 'Height'
        weightKey = 'Weight'

        gridRight1Key = 'Grip strength (right 1)'
        gridRight2Key = 'Grip strength (right 2)'
        gridLeft1Key = 'Grip strength (left 1)'
        gridLeft2Key = 'Grip strength (left 2)'

        situpKey = 'Sit up'
        trunkFlexion1 = 'Trunk flexion 1'
        trunkFlexion2 = 'Trunk flexion 2'

        sideStep1Key = 'Side Step 1'
        sideStep2Key = 'Side Step 2'

        shuttleRunKey = 'Shuttle run'
        sprintRun50mKey = '50m sprint'

        standingJump1Key = 'Standing long jump 1'
        standingJump2Key = 'Standing long jump 2'

        ballThrown1Key = 'Ball throw 1'
        ballThrown2Key = 'Ball throw 2'

        enduranceRunMinutesKey = 'Endurance running (minutes)'
        enduranceRunSecondsKey = 'Endurance running (seconds)'
      }

      for (let i = 0; i < rows.length; i++) {
        const row = rows[i]

        const schoolGradeNumber = getValidPositiveNumberFromExcelFile(
          row[gradeKey],
        )
        const schoolClassNumber = getValidPositiveNumberFromExcelFile(
          row[classKey],
        )
        const schoolAttendanceNumber = getValidPositiveNumberFromExcelFile(
          row[studentNumberKey],
        )

        if (
          !schoolGradeNumber ||
          !schoolClassNumber ||
          !schoolAttendanceNumber
        ) {
          console.error('this row is invalid:', JSON.stringify(row))
          errRowIdsString += `${i + 1}、`
          continue
        }

        const values = {
          schoolGrade: schoolGradeNumber,
          schoolClass: schoolClassNumber,
          schoolAttendanceNumber: schoolAttendanceNumber,
          height: getValIfNum(row[heightKey]),
          weight: getValIfNum(row[weightKey]),
          gripRight1: getValIfNum(row[gridRight1Key]),
          gripRight2: getValIfNum(row[gridRight2Key]),
          gripLeft1: getValIfNum(row[gridLeft1Key]),
          gripLeft2: getValIfNum(row[gridLeft2Key]),

          sitUps: getValIfNum(row[situpKey]),

          bending1: getValIfNum(row[trunkFlexion1]),
          bending2: getValIfNum(row[trunkFlexion2]),

          sideJump1: getValIfNum(row[sideStep1Key]),
          sideJump2: getValIfNum(row[sideStep2Key]),

          shuttleRun: getValIfNum(row[shuttleRunKey]),
          sprintRun: getValIfNum(row[sprintRun50mKey]),

          standingJump1: getValIfNum(row[standingJump1Key]),
          standingJump2: getValIfNum(row[standingJump2Key]),

          handballThrow1: getValIfNum(row[ballThrown1Key]),
          handballThrow2: getValIfNum(row[ballThrown2Key]),

          runningTime: getValIfNum(row[enduranceRunMinutesKey]),
          runningTimeSeconds: getValIfNum(row[enduranceRunSecondsKey]),
        }

        _data.push(values)
        filterNotNullTestResAndAddToArr(submitData, values)
      }

      if (errRowIdsString.length > 0) {
        // remove unnecessary comma characters.
        errRowIdsString = errRowIdsString.substring(
          0,
          errRowIdsString.length - 1,
        )

        toast.error(
          t('行番号{{ids}}に無効なデータがあります', {
            ids: errRowIdsString,
          }),
        )
      }

      setData(_data)
      setPostData(submitData)
    }
  }

  const steps = [
    {
      title: `①${t('登録用エクセルファイルをダウンロード')}`,
      content: (
        <div className="steps-content flex items-center justify-center">
          <div className="border border-primary rounded-5px w-8 h-8 -mr-2">
            <DownloadOutlined
              className="text-2xl"
              onClick={() => downloadTemplate(isUsingJp)}
            />
          </div>
          <Button type="primary" onClick={() => downloadTemplate(isUsingJp)}>
            {t('登録用エクセルファイルをダウンロード')}
          </Button>
        </div>
      ),
    },
    {
      title: `②${t('記入例')}`,
      content: (
        <div className="steps-content flex flex-col items-center justify-between pt-5">
          <img
            className="mb-5"
            src={
              isUsingJp ? '/test_result_demo.png' : '/test_result_demo_en.png'
            }
            alt=""
          />

          <Checkbox
            className="font-black"
            checked={confirmed1}
            onChange={(e) => setConfirmed1(e.target.checked)}
          >
            {t('記入例を確認しましたか？')}
          </Checkbox>
        </div>
      ),
    },
    {
      title: `③${t('測定結果をアップロード')}`,
      content: (
        <div className="steps-content flex flex-col items-center justify-center">
          <div className="h-14 border p-3 border-warn">
            <WarningOutlined className="text-3xl warn-icon" />
            <Text type="danger" className="font-black">
              {t('①でダウンロードしたファイルをアップロードしてください。')}
            </Text>
          </div>
          <div className="flex mt-6">
            <Upload
              multiple={false}
              name="logo"
              listType="text"
              maxCount={1}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              beforeUpload={(file) => {
                parseXlsx(file)
                setUploadFile(file)
                return false
              }}
              onRemove={() => {
                setUploadFile(null)
              }}
            >
              <div className="flex">
                <div className="border border-primary rounded-5px w-8 h-8 -mr-2">
                  <UploadOutlined className="text-2xl" />
                </div>
                <Button type="primary">{t('測定結果をアップロード')}</Button>
              </div>
            </Upload>
          </div>
          <Text className="mb-36">({t('XLSXファイル式')})</Text>
          <Checkbox
            className="font-black"
            checked={confirmed2}
            onChange={(e) => setConfirmed2(e.target.checked)}
          >
            {t('測定結果を正しく記入しましたか？')}
          </Checkbox>
        </div>
      ),
    },
    {
      title: `④${t('確認')}`,
      content: (
        <div className="steps-content flex flex-col items-center justify-center">
          <Table
            columns={testResultTableColumns(t)}
            dataSource={data}
            rowKey="attendanceNumber"
            size="small"
            style={{ minWidth: 900 }}
            className="mb-4"
            rowClassName="font-bold text-black"
            bordered={true}
            pagination={{
              hideOnSinglePage: true,
              defaultPageSize: 50,
              position: ['bottomCenter'],
            }}
          />
          <Checkbox
            className="font-black"
            checked={confirmed3}
            onChange={(e) => setConfirmed3(e.target.checked)}
            disabled={data?.length === 0}
          >
            {t('記入したデータは正しいでしょうか？')}
          </Checkbox>
        </div>
      ),
    },
  ]

  return (
    <Dashboard selectedMenu={3} navbar={t('測定結果をエクセルでアップロード')}>
      {!registerComplete ? (
        <div className="flex justify-center">
          <div className="mt-16" style={{ minWidth: '900px' }}>
            <Steps
              labelPlacement="vertical"
              size="default"
              current={currentStepIdx}
              onChange={() => {}}
            >
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content">{steps[currentStepIdx].content}</div>
            <div className="steps-action text-center">
              {currentStepIdx > 0 && (
                <Button
                  type="primary"
                  className="h-8 w-24 mx-2"
                  onClick={() => prev()}
                >
                  {t('戻る')}
                </Button>
              )}
              {currentStepIdx < steps.length - 1 && (
                <Button
                  type="primary"
                  className="h-8 w-24"
                  loading={uploading}
                  onClick={() => next()}
                  disabled={disabled}
                >
                  {t('次へ')}
                </Button>
              )}
              {currentStepIdx === steps.length - 1 && (
                <Button
                  type="primary"
                  className="h-8 w-24"
                  loading={uploading}
                  onClick={() => register()}
                  disabled={disabled}
                >
                  {t('登録')}
                </Button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <CompletionLayout
          message={t('登録完了')}
          button={
            <Button type="primary" onClick={() => history.push('/test_result')}>
              {t('測定結果を閲覧する')}
            </Button>
          }
        />
      )}
    </Dashboard>
  )
}

export default TestResultUpload
