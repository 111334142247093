import { calculateBmi } from '~/utils/bmi'
import { currentYear } from '~/utils/constants'
import { IStudent } from '~/utils/types/api/res'

export const getTestResults = (student: IStudent) => {
  return student.attributes.testResults?.find((d) => d.testYear === currentYear)
}

export const sortTestResults = (a, b, key) => {
  let element1: undefined | number | string
  let element2: undefined | number | string

  switch (key) {
    case 'grip':
    case 'sitUps':
    case 'bending':
    case 'sprintRun':
    case 'sideJump':
    case 'standingJump':
    case 'shuttleRun':
    case 'handballThrow':
    case 'enduranceRun': {
      const a_tr = getTestResults(a)
      const b_tr = getTestResults(b)

      element1 = a_tr?.[key]?.value
      element2 = b_tr?.[key]?.value
      break
    }
    case 'rank':
    case 'points': {
      const a_tr = getTestResults(a)
      const b_tr = getTestResults(b)

      element1 = a_tr?.[key]
      element2 = b_tr?.[key]
      break
    }
    case 'height':
    case 'weight': {
      const a_tr = getTestResults(a)
      const b_tr = getTestResults(b)

      element1 = a_tr?.sizeTest?.[key]
      element2 = b_tr?.sizeTest?.[key]
      break
    }
    case 'bmi': {
      const a_tr = getTestResults(a)
      const b_tr = getTestResults(b)

      element1 = calculateBmi(a_tr?.sizeTest)
      element2 = calculateBmi(b_tr?.sizeTest)
      break
    }
    case 'name': {
      element1 = `${a?.familyName ?? ''}${a?.givenName ?? ''}`
      element2 = `${b?.familyName ?? ''}${b?.givenName ?? ''}`
      break
    }
    default: {
      element1 = a?.[key]
      element2 = b?.[key]
    }
  }

  if (element1 == null && element2 == null) return 1
  if (element1 == null) return -1
  if (element2 == null) return 1

  return typeof element1 === 'number'
    ? element1 - (element2 as number)
    : element1.localeCompare(element2 as string)
}
