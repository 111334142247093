import './SimpleVideoModal.css'

import { CloseOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import { memo } from 'react'
import SimpleAutoPlayVideo from '../cards/SimpleAutoPlayVideo'

interface SimpleVideoModalProps {
  video: {
    url: string
    thumb: string
  }
  onClose: () => void
}

/**
 * Using for setting page, where video is not tracked.
 * For example, not counting views.
 */
const SimpleVideoModal = memo(({ video, onClose }: SimpleVideoModalProps) => {
  const ratio = 0.67

  return (
    <Modal
      visible={!!video}
      onCancel={onClose}
      footer={null}
      centered
      width="auto"
      closeIcon={
        <CloseOutlined
          className="absolute -top-8 -right-1 text-2xl opacity-80 font-bold hover:opacity-100"
          style={{ color: '#FFF' }}
        />
      }
      className="video-modal"
    >
      <SimpleAutoPlayVideo
        src={video.url}
        size={{
          width: window.screen.width * ratio,
          height: window.screen.height * ratio,
        }}
        thumb={video.thumb}
      />
    </Modal>
  )
})

export default SimpleVideoModal
