export const showBmi = (
  sizeTest:
    | { weight?: number | null; height?: number | null }
    | undefined
    | null,
): string => {
  if (sizeTest?.weight && sizeTest.height) {
    return `${Number(
      ((sizeTest.weight * 10000) / (sizeTest.height * sizeTest.height)).toFixed(
        2,
      ),
    )}`
  }

  return '-'
}

export const calculateBmiByExistWeightHeight = (sizeTest: {
  weight: number
  height: number
}): string => {
  return (
    (sizeTest.weight * 10000) /
    (sizeTest.height * sizeTest.height)
  ).toFixed(2)
}

export const calculateBmi = (
  sizeTest:
    | {
        weight?: number | null
        height?: number | null
      }
    | undefined
    | null,
): number => {
  if (sizeTest?.weight && sizeTest?.height) {
    return Number(
      ((sizeTest.weight * 10000) / (sizeTest.height * sizeTest.height)).toFixed(
        2,
      ),
    )
  }

  return 0
}
