import {
  Button,
  Col,
  Form,
  Grid,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Table,
} from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CloseOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/lib/table'
import _ from 'lodash'
import toast from 'react-hot-toast'
import { useAlphaStore } from '~/context'
import {
  checkIfValidEmailIncludingFullWidth,
  normalizeJpEmail,
} from '~/school-utils/validations'
import Api from '~/utils/api'
import {
  currentYear,
  pageSize as defaultPageSize,
  TUserGender,
} from '~/utils/constants'
import isJapanese from '~/utils/isJapanese'
import { getAscendingOrDescendingListData } from '~/utils/number'
import { Dashboard } from '../layout/Dashboard'
import { incorrectValue } from '../shared/IncorrectValue'

// WARN: When using English, given names and family names order is reversed,
// so the family name (Last Name) is saved in the given name (First Name) in DB.

const { Option } = Select
const { useBreakpoint } = Grid

interface IStudent {
  attributes: {
    _id: string
    givenName: string
    givenNameHiragana: string | null
    familyName: string
    familyNameHiragana: string | null
    gender: TUserGender
    yearBirth: number | null
    monthBirth: number | null
    dayBirth: number | null
    schoolGrade: number
    schoolClass: number
    schoolAttendanceNumber: number
    ssoEmail?: string
  }
  _id: string
}

/**
 * Path: /students
 */
const StudentsPage = () => {
  const { t, i18n } = useTranslation()

  const { school } = useAlphaStore()

  const [listGrades, setListGrades] = useState<number[]>([])
  const [listClasses, setListClasses] = useState<number[]>([])
  const [schoolGrade, setSchoolGrade] = useState(0)
  const [keySort, setKeySort] = useState('')
  const [valueSort, setValueSort] = useState('1')
  const [schoolClass, setSchoolClass] = useState(0)
  const [data, setData] = useState<IStudent[] | undefined>()
  const [filterGender, setFilterGender] = useState('')
  const [isReadyToCallListApi, setIsReadyToCallListApi] = useState(false)
  const [selectedStudent, setSelectedStudent] = useState<any>(null)
  const [showDetailModal, setShowDetailModal] = useState(false)
  const [reloading, setReloading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    total: 0,
    maxPage: 0,
    currentPage: 0,
    pageSize: 0,
  })

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const listYear = getAscendingOrDescendingListData(1970, currentYear, false)
  const listMonth = getAscendingOrDescendingListData(1, 12)
  const [listDay, setListDay] = useState(
    getAscendingOrDescendingListData(1, 31),
  )

  const isUsingJp = isJapanese(i18n)
  const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'

  const [form] = Form.useForm()

  const getFilterData = async () => {
    const { data: dataFilter } = await Api.get<{
      grades: number[]
      classes: number[]
    }>('/alpha/v1/school/filterStudents')

    const grades = dataFilter.grades
    const classes = dataFilter.classes

    setListGrades(grades)
    setSchoolGrade(grades.length ? grades[0] : 0)
    setListClasses(classes)
    setSchoolClass(classes.length ? classes[0] : 0)

    setIsReadyToCallListApi(true)
  }

  useEffect(() => {
    getFilterData().then()
  }, [])

  const handleChange = () => {
    const data = form.getFieldsValue()
    if (!data) return

    if (data.yearBirth && data.monthBirth) {
      /**
       * Get last day of month
       * @example 28
       */
      const lastDayOfMonthBirth = new Date(
        data.yearBirth,
        data.monthBirth,
        0,
      ).getDate()

      setListDay(getAscendingOrDescendingListData(1, lastDayOfMonthBirth))

      if (data.dayBirth && data.dayBirth > lastDayOfMonthBirth) {
        // invalid day
        form.setFieldsValue({
          yearBirth: data.yearBirth,
          monthBirth: data.monthBirth,
          dayBirth: '',
        })
      }
    }
  }

  const getStudentData = async () => {
    setReloading(true)
    const dataParams: string[] = []
    dataParams.push(`currentPage=${currentPage}`)
    dataParams.push(`pageSize=${pageSize}`)
    dataParams.push(`schoolGrade=${schoolGrade}`)
    dataParams.push(`schoolClass=${schoolClass}`)
    dataParams.push(`gender=${filterGender}`)
    dataParams.push(`sorting=${keySort}`)
    dataParams.push(`ordering=${valueSort}`)

    const apiUrl = `/alpha/v1/school/listStudents?${dataParams.join('&')}`
    const { data } = await Api.get(apiUrl)

    const clonedStudent = _.cloneDeep(data.data.studentData)
    const clonedPagination = _.cloneDeep(data.data.paginationOptions)
    const dataClone: IStudent[] = []

    if (clonedStudent.length) {
      clonedStudent.forEach((student) => {
        dataClone.push({ ...student, _id: student.attributes?._id })
      })
    }

    setData(dataClone)
    setPagination(clonedPagination)
    setReloading(false)
  }

  useEffect(() => {
    if (isReadyToCallListApi) {
      getStudentData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    pageSize,
    schoolClass,
    schoolGrade,
    filterGender,
    keySort,
    valueSort,
    isReadyToCallListApi,
  ])

  const sortTests = (element1, value, key) => {
    setKeySort(key)
    setValueSort(value === 'ascend' ? '1' : '-1')
    return element1
  }

  const tableColumns = [
    {
      title: t('学年'),
      dataIndex: ['attributes', 'schoolGrade'],
      key: 'schoolGrade',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b, key) => {
        return sortTests(a, key, 'schoolGrade')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        return <div>{v}</div>
      },
    },
    {
      title: t('組'),
      dataIndex: ['attributes', 'schoolClass'],
      key: 'schoolClass',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b, key) => {
        return sortTests(a, key, 'schoolClass')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        return <div>{v}</div>
      },
    },
    {
      title: t('番'),
      dataIndex: ['attributes', 'schoolAttendanceNumber'],
      key: 'schoolAttendanceNumber',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b, key) => {
        return sortTests(a, key, 'schoolAttendanceNumber')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        return <div>{v}</div>
      },
    },
    {
      title: t('性別'),
      dataIndex: ['attributes', 'gender'],
      key: 'gender',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b, key) => {
        return sortTests(a, key, 'gender')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => <div>{v ? (v === 'MALE' ? t('男') : t('女')) : '-'}</div>,
    },
    {
      title: t('姓'),
      dataIndex: ['attributes', 'familyName'],
      key: 'familyName',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b, key) => {
        return sortTests(a, key, 'familyName')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return <div>{v}</div>
      },
    },
    {
      title: t('名'),
      dataIndex: ['attributes', 'givenName'],
      key: 'givenName',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b, key) => {
        return sortTests(a, key, 'givenName')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return <div>{v}</div>
      },
    },
    !isUsingJp
      ? false
      : {
          title: 'せい',
          dataIndex: ['attributes', 'familyNameHiragana'],
          key: 'familyNameHiragana',
          className: 'text-center-f whitespace-nowrap',
          sorter: (a, b, key) => {
            return sortTests(a, key, 'familyNameHiragana')
          },
          sortDirections: ['descend', 'ascend'],
          render: (v) => {
            return <div>{v}</div>
          },
        },
    !isUsingJp
      ? false
      : {
          title: 'めい',
          dataIndex: ['attributes', 'givenNameHiragana'],
          key: 'givenNameHiragana',
          className: 'text-center-f whitespace-nowrap',
          sorter: (a, b, key) => {
            return sortTests(a, key, 'givenNameHiragana')
          },
          sortDirections: ['descend', 'ascend'],
          render: (v) => {
            return <div>{v}</div>
          },
        },
    {
      title: t('生年月日'),
      dataIndex: ['attributes'],
      key: 'birthday',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b, key) => {
        return sortTests(a, key, 'birthday')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        return (
          <div className="whitespace-nowrap">
            {v.yearBirth &&
              v.monthBirth &&
              v.dayBirth &&
              t('年月日', {
                year: v.yearBirth,
                month: t(`${v.monthBirth}月`),
                day: v.dayBirth,
              })}
          </div>
        )
      },
    },
    {
      title: t('SSO連携用メールアドレス'),
      dataIndex: ['attributes', 'ssoEmail'],
      key: 'ssoEmail',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b, key) => {
        return sortTests(a, key, 'ssoEmail')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return <div>{v}</div>
      },
    },
    {
      title: '',
      dataIndex: ['attributes'],
      key: 'actions',
      render: (v, r) => {
        return (
          <div className="flex justify-center">
            <Button
              className="font-black p-0"
              onClick={(e) => {
                e.preventDefault()
                setSelectedStudent(r)
                form.setFieldsValue({
                  // reset form values, otherwise the form will keep the previous SSO Email values
                  ssoEmail: undefined,
                  ...r.attributes,
                  testYear: currentYear,
                })
                setShowDetailModal(true)
                return false
              }}
              disabled={false}
              style={{ padding: 0, maxHeight: '25px', margin: '7px 0 7px' }}
            >
              <img src="edit_icon.png" alt="" />
            </Button>
          </div>
        )
      },
    },
  ].filter(Boolean)

  const screens = useBreakpoint()

  const createStudent = () => {
    setSelectedStudent({})
    setShowDetailModal(true)
    form.resetFields()
    return false
  }

  const onFinishForm = async () => {
    const formData = form.getFieldsValue()
    if (!formData || formData.schoolGrade == null) {
      toast.error(t('年が入力されていません。'))
      return
    }

    if (formData.yearBirth || formData.monthBirth || formData.dayBirth) {
      if (!formData.yearBirth || !formData.monthBirth || !formData.dayBirth) {
        toast.error(
          t('無効な生年月日が含まれています。確認し、再度登録してください。'),
        )
        return
      }
    }

    setLoading(true)

    const submitData = {
      schoolGrade: formData.schoolGrade,
      schoolClass: formData.schoolClass,
      attendanceNumber: formData.schoolAttendanceNumber,
      gender: formData.gender ?? 'FEMALE',

      familyName: formData.familyName,
      givenName: formData.givenName,
      familyNameHiragana: formData.familyNameHiragana,
      givenNameHiragana: formData.givenNameHiragana,

      yearBirth: formData.yearBirth ? Number(formData.yearBirth) : undefined,
      monthBirth: formData.monthBirth ? Number(formData.monthBirth) : undefined,
      dayBirth: formData.dayBirth ? Number(formData.dayBirth) : undefined,

      ssoEmail: formData.ssoEmail
        ? normalizeJpEmail(formData.ssoEmail)
        : undefined,
    }

    let apiUrl = '/alpha/v1/school/students'

    try {
      if (selectedStudent?.attributes?._id) {
        apiUrl = `/alpha/v1/school/students/${selectedStudent.attributes._id}`
        await Api.patch(apiUrl, submitData)
      } else {
        await Api.post(apiUrl, submitData)
      }

      toast.success(t('編集完了'))
      setShowDetailModal(false)
      await getStudentData()
    } catch (err) {
      const errMess = (err as Error).message
      console.error(`when call ${apiUrl}, error:`, err)
      console.error(`when call ${apiUrl}, error message:`, errMess)
      // try {
      //   const errorResponse = JSON.parse(err as any)
      //   if (errorResponse.error === 'SSO_EMAIL_CONFLICT') {
      //     toast.error(
      //       t('このSSO連携用メールアドレスは既に他の生徒で使用されています。'),
      //       9,
      //     )
      //     return
      //   }
      // } catch {
      //   // Not a JSON error message, continue with other error handling
      // }

      // if (errMess?.includes('409')) {
      //   toast.error(t('入力された情報を持つ学生はすでに存在します。'))
      // }

      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      const errRes = (err as any).response
      console.warn('errRes: ', errRes)

      if (
        // errRes?.status === 409 &&
        errRes?.data?.error === 'SSO_EMAIL_ALREADY_EXISTS'
      ) {
        form.setFields([
          {
            name: 'ssoEmail',
            errors: [
              t('ご入力いただいたメールアドレスは既に登録されています。'),
            ],
          },
        ])
      }
    }

    setLoading(false)
  }

  const confirmToDeleteStudent = async () => {
    const apiUrl = `/alpha/v1/school/deleteStudent/${selectedStudent?.attributes?._id}`

    try {
      await Api.post(apiUrl)
      toast.success(t('編集完了'))
      setIsModalOpen(false)
      setShowDetailModal(false)
      await getStudentData()
    } catch (err) {
      console.error(`when call ${apiUrl}`, err)
    }
  }
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <Dashboard
      selectedMenu={2}
      navbar={t(isElementarySchool ? '児童情報の変更' : '生徒情報の変更')}
    >
      <>
        <Row className="w-full justify-center">
          <Col className="mt-16" xxl={{ span: 20 }} lg={{ span: 22 }}>
            {/* menu */}
            <div className="space-x-10 pb-2 print:hidden flex ml-8">
              <div className="flex items-center">
                <span className="mr-2">{t('学年')}</span>

                <Select
                  className={`rounded-5px ${isUsingJp ? 'w-30' : 'w-36'}`}
                  value={schoolGrade}
                  onChange={setSchoolGrade}
                  dropdownAlign={{
                    offset: [0, -2],
                  }}
                >
                  <Option key={'grade-default'} value={0}>
                    {t('全学年')}
                  </Option>
                  {listGrades.map((v) => (
                    <Option value={v} key={v}>
                      {t('個別学年', {
                        count: v,
                        ordinal: !isUsingJp,
                      })}
                    </Option>
                  ))}
                </Select>
              </div>

              <div className="flex items-center ml-10">
                <span className="mr-2">{t('組')}</span>
                <Select
                  className={`rounded-5px ${isUsingJp ? 'w-30' : 'w-36'}`}
                  value={schoolClass}
                  onChange={setSchoolClass}
                  dropdownAlign={{
                    offset: [0, -2],
                  }}
                >
                  <Option key={'class-default'} value={0}>
                    {t('全組')}
                  </Option>
                  {listClasses.map((_class) => {
                    return (
                      <Option key={`class-${_class}`} value={_class}>
                        {t('個別組', {
                          count: _class,
                          ordinal: !isUsingJp,
                        })}
                      </Option>
                    )
                  })}
                </Select>
              </div>

              <div className="flex items-center ml-10">
                <span className="mr-2">{t('性別')}</span>

                <Select
                  className={`rounded-5px ${isUsingJp ? 'w-30' : 'w-36'}`}
                  value={filterGender}
                  onChange={(g) => setFilterGender(g)}
                  dropdownAlign={{
                    offset: [0, -2],
                  }}
                >
                  <Option key="gender-0" value="">
                    {t('全性別')}
                  </Option>

                  <Option key="gender-male" value="MALE">
                    {t('男性')}
                  </Option>

                  <Option key="gender-female" value="FEMALE">
                    {t('女性')}
                  </Option>
                </Select>
              </div>
            </div>

            <div className="flex justify-center mt-8 flex-col xl:flex-row">
              <div className="mb-4 ml-8">
                <Button
                  type="primary"
                  style={{ marginRight: 44 }}
                  onClick={createStudent}
                >
                  {t('新しい生徒を追加', {
                    student: isElementarySchool ? '児童' : '生徒',
                  })}
                </Button>
              </div>

              <Table
                columns={tableColumns as ColumnsType<object>}
                dataSource={data}
                loading={reloading}
                rowKey="_id"
                size="small"
                style={{ minWidth: 940 }}
                pagination={{
                  pageSize,
                  defaultPageSize: pageSize,
                  position: ['bottomCenter'],
                  showSizeChanger: true,
                  total: pagination?.total,
                  current: currentPage,
                  onChange: (page: number, pageSize: number) => {
                    setCurrentPage(page)
                    setPageSize(pageSize)
                  },
                }}
                bordered={true}
              />
            </div>
          </Col>
        </Row>

        {selectedStudent && showDetailModal && (
          <Modal
            className="student-page"
            visible={showDetailModal}
            width={screens.xxl ? '50vw' : screens.lg ? '65vw' : '70vw'}
            onCancel={() => {
              setShowDetailModal(false)
              form.resetFields()
            }}
            closeIcon={<CloseOutlined className="text-2xl color-blue-f" />}
            footer={null}
          >
            <div
              className={`justify-end mb-10 ${
                selectedStudent?.attributes?._id ? 'flex' : 'hidden'
              }`}
            >
              <div className="flex cursor-pointer mr-12" onClick={showModal}>
                <span className="text-delete color-red">{t('削除')}</span>
                <img src="delete_icon.png" alt="" />
              </div>

              <Modal
                title={
                  <span
                    style={{
                      fontWeight: 700,
                      fontSize: 20,
                      lineHeight: '30px',
                    }}
                  >
                    {t('児童情報を削除しますか？')}
                  </span>
                }
                visible={isModalOpen}
                footer={null}
                closable={false}
                className="mt-4"
              >
                <div
                  className="justify-center flex"
                  style={{
                    fontWeight: 500,
                    fontSize: 14,
                    lineHeight: '21px',
                    margin: '-24px -24px 0',
                    padding: '24px 0',
                    borderTop: '1px solid #C4C4C4',
                    borderBottom: '1px solid #C4C4C4',
                  }}
                >
                  <span>
                    {t('フルネームさんを削除します。', {
                      givenName: selectedStudent?.attributes?.givenName,
                      familyName: selectedStudent?.attributes?.familyName,
                    })}
                  </span>
                </div>
                <div className="mt-8 justify-center flex">
                  <Button
                    className="w-60 mr-2"
                    size="large"
                    onClick={(e) => {
                      handleCancel()
                    }}
                  >
                    {t('キャンセル')}
                  </Button>
                  <Button
                    type="primary"
                    className="w-60 ml-2"
                    size="large"
                    onClick={(e) => {
                      confirmToDeleteStudent()
                    }}
                  >
                    {t('削除')}
                  </Button>
                </div>
              </Modal>
            </div>

            <Form
              form={form}
              layout="vertical"
              onFinish={onFinishForm}
              requiredMark={false}
            >
              <Row className="mb-8">
                <Col span={12} className="pl-2">
                  <div className="flex">
                    <div className="mr-1 w-30">
                      <label className="ant-form-item-required">
                        {t('学年')}
                      </label>

                      <Form.Item
                        name="schoolGrade"
                        rules={[
                          {
                            required: true,
                            message: t('年が入力されていません。'),
                          },
                          {
                            type: 'number',
                            min: 1,
                            message: t('1以上の数字を入力してください'),
                          },
                        ]}
                      >
                        <InputNumber className="w-30 rounded-5px" />
                      </Form.Item>
                    </div>
                    <div className="mr-1 w-30">
                      <span>{t('組')}</span>
                      <Form.Item
                        name="schoolClass"
                        rules={[
                          {
                            required: true,
                            message: t('組が入力されていません。'),
                          },
                          {
                            type: 'number',
                            min: 1,
                            message: t('1以上の数字を入力してください'),
                          },
                        ]}
                      >
                        <InputNumber className="w-30 rounded-5px" />
                      </Form.Item>
                    </div>

                    <div className="w-30">
                      <span>{t('番')}</span>
                      <Form.Item
                        name="schoolAttendanceNumber"
                        rules={[
                          {
                            required: true,
                            message: t('番が入力されていません。'),
                          },
                          {
                            type: 'number',
                            min: 1,
                            message: t('1以上の数字を入力してください'),
                          },
                        ]}
                      >
                        <InputNumber className="w-30 rounded-5px" />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col span={12} className="pl-2">
                  <div>
                    <span className="mr-2">{t('性別')}</span>
                    <Form.Item name="gender">
                      <Select
                        className="w-30 rounded-5px"
                        dropdownAlign={{
                          offset: [0, -2],
                        }}
                        style={{ width: 120 }}
                        defaultValue="FEMALE"
                      >
                        <Option key="gender-male" value="MALE">
                          {t('男性')}
                        </Option>
                        <Option key="gender-female" value="FEMALE">
                          {t('女性')}
                        </Option>
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row className="mb-8">
                <Col span={12} className="pl-2">
                  <span className="mx-2 textLabel">{t('姓')}</span>
                  <Form.Item name="familyName">
                    <Input className="w-30 rounded-5px" />
                  </Form.Item>
                </Col>
                <Col span={12} className="pl-2">
                  <span className="mx-2 textLabel">{t('名')}</span>
                  <Form.Item name="givenName">
                    <Input className="w-30 rounded-5px" />
                  </Form.Item>
                </Col>
              </Row>

              {isUsingJp && (
                <Row className="mb-8">
                  <Col span={12} className="pl-2">
                    <span className="mx-2 textLabel">せい</span>
                    <Form.Item name="familyNameHiragana">
                      <Input className="w-30 rounded-5px" />
                    </Form.Item>
                  </Col>
                  <Col span={12} className="pl-2">
                    <span className="mx-2 textLabel">めい</span>
                    <Form.Item name="givenNameHiragana">
                      <Input className="w-30 rounded-5px" />
                    </Form.Item>
                  </Col>
                </Row>
              )}

              <Row className="mb-8">
                <Col span={12} className="pl-2">
                  <span className="mx-2 textLabel">{t('生年月日')}</span>
                  <div className="flex align-center items-center">
                    <Form.Item name="yearBirth">
                      <Select
                        style={{ width: 120 }}
                        onChange={handleChange}
                        options={listYear}
                      />
                    </Form.Item>

                    <span className="mx-2 textDate mb-2">
                      {isUsingJp ? '年' : ''}
                    </span>

                    <Form.Item name="monthBirth">
                      <Select
                        style={{ width: 80 }}
                        onChange={handleChange}
                        options={listMonth}
                      />
                    </Form.Item>
                    <span className="mx-2 textDate mb-2">
                      {isUsingJp ? '月' : ''}
                    </span>
                    <Form.Item name="dayBirth">
                      <Select
                        style={{ width: 80 }}
                        onChange={handleChange}
                        options={listDay}
                      />
                    </Form.Item>
                    <span className="mx-2 textDate mb-2">
                      {isUsingJp ? '日' : ''}
                    </span>
                  </div>
                </Col>
                <Col span={12} className="pl-2" />
              </Row>

              <Row className="mb-8">
                <Col span={12} className="pl-2">
                  <span className="mx-2 textLabel">
                    {t('SSO連携用メールアドレス')}
                  </span>

                  <Form.Item
                    name="ssoEmail"
                    rules={[
                      {
                        // `async` is must!
                        validator: async (_, value) => {
                          if (
                            value &&
                            !checkIfValidEmailIncludingFullWidth(value)
                          ) {
                            throw new Error(
                              t('メールアドレスの形式が正しくありません'),
                            )
                          }
                        },
                      },
                    ]}
                  >
                    <Input className="w-30 rounded-5px" />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mb-8" justify="center">
                <Form.Item>
                  <Button
                    className="w-60 mr-2"
                    size="large"
                    onClick={(e) => {
                      e.preventDefault()
                      setShowDetailModal(false)
                    }}
                  >
                    {t('backToList')}
                  </Button>

                  <Button
                    type="primary"
                    htmlType="submit"
                    className="w-60 ml-2"
                    size="large"
                    loading={loading}
                    disabled={loading}
                  >
                    {selectedStudent?.attributes?._id
                      ? t('修正する')
                      : t('追加する')}
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          </Modal>
        )}
      </>
    </Dashboard>
  )
}

export default StudentsPage
