import {
  DownloadOutlined,
  UploadOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { Button, Checkbox, Steps, Table, Typography, Upload } from 'antd'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import toast from 'react-hot-toast'
import CompletionLayout from '~/components/layout/CompletionLayout'
import { useAlphaStore } from '~/context'
import isJapanese from '~/utils/isJapanese'
import { filterNotNullTestResAndAddToArr } from '~/utils/test-result'
import { Dashboard } from '../layout/Dashboard'
import { testResultTableColumns } from './test-result/utils/constants'

const { Step } = Steps
const { Text } = Typography

const downloadTemplate = (isUsingJp: boolean) => {
  if (isUsingJp) {
    window.open(
      '/upload_sample/体力テスト記録メモ用紙テンプレート.pdf',
      '_blank',
    )
  } else {
    window.open(
      '/upload_sample/体力テスト記録メモ用紙テンプレート.pdf',
      '_blank',
    )
  }
}

/**
 * Path: test_result_pdf_upload
 */
const TestResultPdfUpload = () => {
  const { school } = useAlphaStore()
  const { t, i18n } = useTranslation()

  const [disabled, setDisabled] = useState(true)
  const [uploadFile, setUploadFile] = useState<any>()
  const [uploading, setUploading] = useState(false)

  const [data, setData] = useState<any[]>()
  const [postData, setPostData] = useState<any[]>()
  const [registerComplete, setRegisterComplete] = useState(false)
  const [currentStepIdx, setCurrentStepIdx] = useState(0)
  const [confirmed1, setConfirmed1] = useState(false)
  const [confirmed2, setConfirmed2] = useState(false)
  const [confirmed3, setConfirmed3] = useState(false)

  const history = useHistory()

  const isUsingJp = isJapanese(i18n)

  useEffect(() => {
    switch (currentStepIdx) {
      case 0:
        setDisabled(false)
        break
      case 1:
        setDisabled(!confirmed1)
        break
      case 2:
        setDisabled(!confirmed2)
        break
      case 3:
        setDisabled(!confirmed3)
        break
    }
  }, [currentStepIdx, confirmed1, confirmed2, confirmed3])

  const onFinish = async () => {
    if (!school) {
      toast.error(`${t('エラーが発生しました。')} [school is not found]`)
      console.error('TestResultUpload - onFinish, error: school is not found')
      return
    }

    setUploading(true)
    /**
     * Determine whether when this file is uploaded.
     */
    // upload test_result data
    try {
      await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_REST_API_URL}/alpha/v1/school/upload-results`,
        data: postData,
      })

      toast.success(t('アップロードしました。'))
      // form.resetFields()
      setUploadFile(null)
      setUploading(false)
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    } catch (err: any) {
      console.error(err)

      if (err.response?.data?.error === 'STUDENT_NOT_FOUND') {
        toast.error(
          `${t('エラーが発生しました。')} ${t('生徒が見つかりません。')}`,
        )
      } else {
        toast.error(`${t('エラーが発生しました。')}`)
      }

      setUploading(false)
    }
  }

  const register = async () => {
    await onFinish()
    setRegisterComplete(true)
  }

  const next = async () => {
    const nextStepIdx = currentStepIdx + 1
    if (nextStepIdx === 3 && !uploadFile) {
      toast.error(t('Excelファイルを選んでください。'))
      return
    }

    setCurrentStepIdx(nextStepIdx)
  }

  const prev = () => {
    setCurrentStepIdx(currentStepIdx - 1)
  }

  const steps = [
    {
      title: `①${t('体力テスト記録用紙をダウンロード')}`,
      content: (
        <div className="steps-content flex items-center justify-center">
          <div className="border border-primary rounded-5px w-8 h-8 -mr-2">
            <DownloadOutlined
              className="text-2xl"
              onClick={() => downloadTemplate(isUsingJp)}
            />
          </div>
          <Button type="primary" onClick={() => downloadTemplate(isUsingJp)}>
            {t('体力テスト記録用紙をダウンロード')}
          </Button>
        </div>
      ),
    },
    {
      title: `②${t('アップロードPDFファイルの作成')}`,
      content: (
        <div className="steps-content flex flex-col items-center justify-between pt-5">
          <div className="flex justify-center items-center mb-8">
            <div className="w-96">
              <div className="font-bold text-xl text-center">準備すること</div>
              <br />
              <b>＜記録入力＞</b>
              <br />
              ・ダウンロードした記録用紙に記録を漏れなく入力してください。
              <br />
              <b>＜スキャン＞</b>
              <br />
              ・以下の画像の向きで記録用紙が映るようにスキャンをしPDFを準備してください。
              <br />
              ・複数の記録用紙を同時にアップロードする場合、全ての記録用紙が1つのPDFとなるようにスキャンをしてください。
              <br />
            </div>
            <div className="border-2 border-blue-100 rounded ml-12">
              <img className="w-72" src={'/test_result_pdf_demo.jpg'} alt="" />
            </div>
          </div>

          <Checkbox
            className="font-black"
            checked={confirmed1}
            onChange={(e) => setConfirmed1(e.target.checked)}
          >
            {t('上記の準備作業に従ってPDFファイルを準備できましたか？')}
          </Checkbox>
        </div>
      ),
    },
    {
      title: `③${t('測定結果をアップロード')}`,
      content: (
        <div className="steps-content flex flex-col items-center justify-center">
          <div className="h-14 border p-3 border-warn">
            <WarningOutlined className="text-3xl warn-icon" />
            <Text type="danger" className="font-black">
              {t('②で作成したPDFファイルをアップロードしてください。')}
            </Text>
          </div>
          <div className="flex mt-6">
            <Upload
              multiple={false}
              name="logo"
              listType="text"
              maxCount={1}
              accept="application/pdf"
              beforeUpload={async (file) => {
                // parseXlsx(file)
                setUploadFile(file)
                setConfirmed2(true)
                const schoolId = school?._id
                const schoolName = school?.attributes?.schoolName
                const fileId = `${schoolName}_${schoolId}_${Date.now()}`

                // const studentListFormData = new FormData()
                // studentListFormData.append('file', file)
                // studentListFormData.append('filename', fileId)
                setUploading(true)
                const fileName = `alpha/pdf-uploads/${fileId}.pdf`
                await axios
                  .post(
                    `${process.env.REACT_APP_REST_API_URL}/alpha/generate-upload-url`,
                    { fileName },
                  )
                  .then(async (res) => {
                    const presignedUploadUrl = res.data.url
                    await axios.put(presignedUploadUrl, file, {
                      headers: {
                        'Content-Type': 'application/octet-stream',
                      },
                    })
                    const { data: values } = await axios.post(
                      `${process.env.REACT_APP_REST_API_URL}/alpha/v1/school/ocr-test-results`,
                      { fileName },
                    )
                    const submitData: Record<string, number | object>[] = []

                    for (const value of values) {
                      filterNotNullTestResAndAddToArr(submitData, value)
                    }
                    setData(values)
                    setPostData(submitData)
                  })
                  .catch((err: unknown) => {
                    console.error('alpha/pdf-upload API error:', err)
                    throw err
                  })
                setUploading(false)
                return false
              }}
              onRemove={() => {
                setConfirmed2(false)
                setUploadFile(null)
              }}
            >
              <div className="flex">
                <div className="border border-primary rounded-5px w-8 h-8 -mr-2">
                  <UploadOutlined className="text-2xl" />
                </div>
                <Button type="primary">{t('測定結果をアップロード')}</Button>
              </div>
            </Upload>
          </div>
          <Text className="mb-36">({t('PDFファイル形式')})</Text>
        </div>
      ),
    },
    {
      title: `④${t('確認')}`,
      content: (
        <div className="steps-content flex flex-col items-center justify-center">
          <Table
            columns={testResultTableColumns(t)}
            dataSource={data}
            rowKey="attendanceNumber"
            size="small"
            style={{ minWidth: 900 }}
            className="mb-4"
            rowClassName="font-bold text-black"
            bordered={true}
            pagination={{
              hideOnSinglePage: true,
              defaultPageSize: 50,
              position: ['bottomCenter'],
            }}
          />
          <Checkbox
            className="font-black"
            checked={confirmed3}
            onChange={(e) => setConfirmed3(e.target.checked)}
            disabled={data?.length === 0}
          >
            {t('記入したデータは正しいでしょうか？')}
          </Checkbox>
        </div>
      ),
    },
  ]

  return (
    <Dashboard selectedMenu={3} navbar={t('測定結果をエクセルでアップロード')}>
      {!registerComplete ? (
        <div className="flex justify-center">
          <div className="mt-16" style={{ minWidth: '900px' }}>
            <Steps
              labelPlacement="vertical"
              size="default"
              current={currentStepIdx}
              onChange={() => {}}
            >
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content">{steps[currentStepIdx].content}</div>
            <div className="steps-action text-center">
              {currentStepIdx > 0 && (
                <Button
                  type="primary"
                  className="h-8 w-24 mx-2"
                  onClick={() => prev()}
                >
                  {t('戻る')}
                </Button>
              )}
              {currentStepIdx < steps.length - 1 && (
                <Button
                  type="primary"
                  className="h-8 w-24"
                  loading={uploading}
                  onClick={() => next()}
                  disabled={disabled}
                >
                  {t('次へ')}
                </Button>
              )}
              {currentStepIdx === steps.length - 1 && (
                <Button
                  type="primary"
                  className="h-8 w-24"
                  loading={uploading}
                  onClick={() => register()}
                  disabled={disabled}
                >
                  {t('登録')}
                </Button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <CompletionLayout
          message={t('登録完了')}
          button={
            <Button type="primary" onClick={() => history.push('/test_result')}>
              {t('測定結果を閲覧する')}
            </Button>
          }
        />
      )}
    </Dashboard>
  )
}

export default TestResultPdfUpload
