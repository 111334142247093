import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './locales/en/translation.json'
import ja from './locales/ja/translation.json'
import { LOCAL_STORAGE_LANGUAGE_KEY, langParam } from './utils/constants'
import { displayNoneFairNav } from './utils/ele'

/**
 * Get language from localStorage.
 * When On iOS Safari, localStorage is disabled in Private Browsing
 * mode, causing window.localStorage to be null.
 */
const storageLang = window.localStorage
  ? window.localStorage.getItem(LOCAL_STORAGE_LANGUAGE_KEY)
  : undefined

const lng = langParam ?? storageLang ?? (window.navigator.language || 'ja')

i18n
  .use(initReactI18next)
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  // .use(Backend)
  .init({
    resources: {
      en: {
        translation: en,
      },
      ja: {
        translation: ja,
      },
    },
    lng,
    // detection: {
    //   order: ['localStorage', 'navigator'],
    //   lookupLocalStorage: 'language',
    // },
    interpolation: {
      // react already safes from xss
      escapeValue: false,
    },

    fallbackLng: 'ja',
    // debug: true,
  })

if (lng.startsWith('en')) {
  window.document.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
      displayNoneFairNav()
    }, 1600)
  })
}

export default i18n
