export const getFullName = (
  familyName: string | undefined | null,
  givenName: string | undefined | null,
  isUsingJp: boolean,
): string => {
  const familyNameStr = familyName || ''
  const givenNameStr = givenName || ''
  const spaceBetweenName = isUsingJp ? '' : ' '

  return `${familyNameStr}${spaceBetweenName}${givenNameStr}`
}

export const removeSpaceAndJpSpace = (name: string) => {
  const nameStr = name.trim()

  if (!nameStr.includes('　')) {
    return nameStr
  }

  // should not remove Japanese full-width spaces from middle
  // find start and end index of char Alphabet
  let startIndex = 0
  let endIndex = nameStr.length - 1
  let startFound = false
  for (let i = 0; i < nameStr.length; i++) {
    const char = nameStr[i]

    if (!startFound && char !== '　' && char !== ' ') {
      // starting index
      startIndex = i
      startFound = true
    }

    if (startFound && char !== '　' && char !== ' ') {
      // ending index
      endIndex = i
    }
  }

  return nameStr.slice(startIndex, endIndex + 1)
}
