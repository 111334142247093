import { Form, FormInstance, InputNumber } from 'antd'
import { memo, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { allTestKeys } from '~/utils'
import { getNumOrNull } from '~/utils/number'
import { TAllTestKey, TAllTestKeyHeightWeight } from '~/utils/test-const'
import { getInputRulesForTestRes } from '../constants'
import { checkIfIsInWarningRange } from '../utils/highlight'

interface TestInputFieldProps {
  dataIndex: string
  studentId: string
  isDisabled: boolean
  mainTestIndex: TAllTestKeyHeightWeight
  isElementarySchool: boolean
  isHighlightInputtedVal: boolean
  saveStudent: (studentId: string, dataIndex: string) => void
  setMeasurableModalObj: (obj: { studentId: string; keyId: number }) => void
  formEdit: FormInstance
}

const TestInputField: React.FC<TestInputFieldProps> = memo(
  ({
    dataIndex,
    studentId,
    isDisabled,
    isElementarySchool,
    isHighlightInputtedVal,
    saveStudent,
    setMeasurableModalObj,
    mainTestIndex,
    formEdit,
  }) => {
    const { t } = useTranslation()
    const rules = getInputRulesForTestRes(t)[dataIndex]
    const name = `${dataIndex}_${studentId}`
    const value = formEdit.getFieldValue(name)

    const isInWarningRange = checkIfIsInWarningRange(
      dataIndex,
      value,
      isElementarySchool,
    )

    // const isShowingWarning = isInWarningRange && isHighlightInputtedVal
    const [isShowingWarning, setIsShowingWarning] = useState(
      isInWarningRange && isHighlightInputtedVal,
    )

    // rerender if value of `isHighlightInputtedVal` changes
    useEffect(() => {
      const currValue = formEdit.getFieldValue(name)

      const currIsInWarningRange = checkIfIsInWarningRange(
        dataIndex,
        currValue,
        isElementarySchool,
      )
      setIsShowingWarning(currIsInWarningRange && isHighlightInputtedVal)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHighlightInputtedVal])

    const inputNode = (
      <Form.Item
        name={name}
        style={{ marginTop: 8, width: 120 }}
        className={isDisabled ? 'pointer-events-none' : ''}
        rules={rules}
        validateTrigger={['onSubmit', 'onBlur']}
      >
        <InputNumber
          onInput={(text) => {
            const value = getNumOrNull(text)
            const isInWarningRange = checkIfIsInWarningRange(
              dataIndex,
              value,
              isElementarySchool,
            )

            setIsShowingWarning(isInWarningRange && isHighlightInputtedVal)
          }}
          disabled={isDisabled}
          className={
            isShowingWarning ? 'border-yellow-400-f bg-yellow-fff5db-f' : ''
          }
          onBlur={() => saveStudent(studentId, dataIndex)}
        />
      </Form.Item>
    )

    return isDisabled ? (
      <div
        onClick={() => {
          const keyId = allTestKeys.indexOf(mainTestIndex as TAllTestKey)
          if (keyId < 0 || 8 < keyId) {
            toast.error(`${t('エラーが発生しました。')} key name is invalid!`)
            return
          }

          setMeasurableModalObj({ studentId, keyId })
        }}
      >
        {inputNode}
      </div>
    ) : (
      inputNode
    )
  },
  (prevProps, nextProps) => {
    return (
      // other props are not changed!
      // prevProps.dataIndex === nextProps.dataIndex &&
      // prevProps.studentId === nextProps.studentId &&
      prevProps.isDisabled === nextProps.isDisabled &&
      // prevProps.isElementarySchool === nextProps.isElementarySchool &&
      prevProps.isHighlightInputtedVal === nextProps.isHighlightInputtedVal
      // prevProps.mainTestIndex === nextProps.mainTestIndex
    )
  },
)

export default TestInputField
