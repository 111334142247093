import { Row } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAlphaStore } from '~/context'
import { videoTypes } from '~/school-utils/constants'
import isJapanese from '~/utils/isJapanese'
import ExternalLink from '../cards/ExternalLink'
import Video from '../cards/Video'
import VideoQualitySettingsLink from '../cards/VideoQualitySettingsLink'
import { Dashboard } from '../layout/Dashboard'
import { VideoModal } from '../modal/VideoModal'
import ScreenLoading from '../shared/loading/ScreenLoading'

interface IAdviseMovie {
  url: string
  lowUrl: string
  thumb: string
  title: string
  desc?: string
}

export const adviseMovies: IAdviseMovie[] = [
  {
    url: 'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/009_introduction/video/009_introduction_480p.mp4',
    lowUrl:
      'https://alpha-2023.s3.ap-northeast-1.amazonaws.com/exercise_videos_low_quality/intro.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/thumb/009_introduction.jpg',
    title: '右代啓祐選手からのメッセージ',
  },
  {
    url: 'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/005_grip_strength/video/005_grip_strength_480p.mp4',
    lowUrl:
      'https://alpha-2023.s3.ap-northeast-1.amazonaws.com/exercise_videos_low_quality/grip.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/thumb/005_grip_strength.jpg',
    title: '新体力テストのコツ「握力」',
    desc: '右代啓祐選手の得点はいくつだろう？動画を見て得点アップに繋げてみよう！',
  },
  {
    url: 'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/007_sit-up/video/007_sit-up_480p.mp4',
    lowUrl:
      'https://alpha-2023.s3.ap-northeast-1.amazonaws.com/exercise_videos_low_quality/sit_ups.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/thumb/007_sit-up.jpg',
    title: '新体力テストのコツ「上体起こし」',
    desc: 'どんな筋肉が強くなる？動画を見て得点アップに繋げてみよう！',
  },
  {
    url: 'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/008_flexibility/video/008_flexibility_480p.mp4',
    lowUrl:
      'https://alpha-2023.s3.ap-northeast-1.amazonaws.com/exercise_videos_low_quality/bending.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/thumb/008_flexibility.jpg',
    title: '新体力テストのコツ「長座体前屈」',
    desc: 'ワンポイントアドバイスを見て得点アップに繋げてみよう！',
  },
  {
    url: 'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/006_sidestep/video/006_sidestep_480p.mp4',
    lowUrl:
      'https://alpha-2023.s3.ap-northeast-1.amazonaws.com/exercise_videos_low_quality/side_jump.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/thumb/006_sidestep.jpg',
    title: '新体力テストのコツ「反復横跳び」',
    desc: '右代啓祐選手の得点はいくつだろう？動画を見て得点アップに繋げてみよう！',
  },
  {
    url: 'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/001_50m/video/001_50m_480p.mp4',
    lowUrl:
      'https://alpha-2023.s3.ap-northeast-1.amazonaws.com/exercise_videos_low_quality/sprint_run.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/thumb/001_50m.jpg',
    title: '新体力テストのコツ「50m走」',
    desc: 'どんな筋肉が強くなる？動画を見て得点アップに繋げてみよう！',
  },
  {
    url: 'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/002_20m_shuttle_run/video/002_20m_shuttle_run_480p.mp4',
    lowUrl:
      'https://alpha-2023.s3.ap-northeast-1.amazonaws.com/exercise_videos_low_quality/shuttle_run.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/thumb/002_20m_shuttle_run.jpg',
    title: '新体力テストのコツ「20mシャトルラン」',
    desc: 'ワンポイントアドバイスを見て得点アップに繋げてみよう！',
  },
  {
    url: 'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/003_broad_jump/video/003_broad_jump_480p.mp4',
    lowUrl:
      'https://alpha-2023.s3.ap-northeast-1.amazonaws.com/exercise_videos_low_quality/standing_jump.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/thumb/003_broad_jump.jpg',
    title: '新体力テストのコツ「立ち幅跳び」',
    desc: '',
  },
  {
    url: 'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/004_throwing_ball/video/004_throwing_ball_480p.mp4',
    lowUrl:
      'https://alpha-2023.s3.ap-northeast-1.amazonaws.com/exercise_videos_low_quality/ball_throw.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/thumb/004_throwing_ball.jpg',
    title: '新体力テストのコツ「ボール投げ」',
    desc: '',
  },
]

/**
 * /advise
 */
const AdvisePage = () => {
  const { t, i18n } = useTranslation()
  const isUsingJp = isJapanese(i18n)

  const { school } = useAlphaStore()
  const isNotElementarySchool = school?.attributes?.schoolCategoryCode !== 'B1'
  const [isLoading, setIsLoading] = useState(true)
  const [thumbnailLoadedCount, setThumbnailLoadedCount] = useState(0)
  const [selectedVideo, setSelectedVideo] = useState<IAdviseMovie | undefined>()
  const [selectedVideoId, setSelectedVideoId] = useState<number | undefined>()

  const onThumbnailLoaded = () => setThumbnailLoadedCount((x) => x + 1)

  useEffect(() => {
    if (thumbnailLoadedCount >= adviseMovies.length) setIsLoading(false)
  }, [thumbnailLoadedCount])

  return (
    <>
      <Dashboard selectedMenu={5} navbar={t('体力テストお手本動画')}>
        <>
          <Row justify="center" className="pt-8">
            <Row style={{ width: 966 }}>
              <div className="w-full pr-4">
                <VideoQualitySettingsLink />
              </div>

              {school?.attributes
                ? adviseMovies.map((m, index) => (
                    <div className="w-1/3 pl-2" key={index}>
                      <div
                        className="cursor-pointer"
                        onClick={(event) => {
                          setSelectedVideo(m)
                          setSelectedVideoId(index)
                          event.stopPropagation()
                        }}
                        onTouchEnd={(event) => {
                          setSelectedVideo(m)
                          setSelectedVideoId(index)
                          event.stopPropagation()
                        }}
                      >
                        <Video
                          src={
                            school.attributes.videoQualitySetting
                              ? m.lowUrl
                              : m.url
                          }
                          size={{ width: 292, height: 164 }}
                          thumb={m.thumb}
                          className="rounded-10px overflow-hidden pointer-events-none"
                          onThumbnailLoaded={onThumbnailLoaded}
                          videoType={videoTypes.advise}
                          keyName={index.toString()}
                        />
                      </div>

                      <Row>
                        <span className="mt-2 mb-9 text-black font-semibold text-base">
                          {t(m.title)}
                          <br />
                          {isNotElementarySchool &&
                            m.title === '新体力テストのコツ「50m走」' && (
                              <ExternalLink url="https://alpha2019.notion.site/50m-bf4339bdc1b341d5932fbb33aa92ee51">
                                ※{t('クラウチングスタートはこちら')}
                              </ExternalLink>
                            )}

                          {isNotElementarySchool &&
                            m.title ===
                              '新体力テストのコツ「20mシャトルラン」' && (
                              <ExternalLink url="https://alpha2019.notion.site/1500-1000-ac7222d50c1440b8853436db0f2fda26">
                                ※{t('持久走の実施方法・コツはこちら')}
                              </ExternalLink>
                            )}

                          {isNotElementarySchool &&
                            isUsingJp &&
                            m.title === '新体力テストのコツ「ボール投げ」' && (
                              <div className="font-normal">
                                ※中学校・高等学校においては
                                <br />
                                ハンドボールを使用して実施
                                <br />
                                してください。
                              </div>
                            )}
                        </span>
                      </Row>
                    </div>
                  ))
                : null}
            </Row>
          </Row>

          {selectedVideo && (
            <VideoModal
              video={selectedVideo}
              onClose={() => setSelectedVideo(undefined)}
              videoType={videoTypes.advise}
              keyName={selectedVideoId?.toString() ?? ''}
            />
          )}
        </>
      </Dashboard>
      <ScreenLoading isLoading={isLoading} />
    </>
  )
}

export default AdvisePage
