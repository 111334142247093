import { FC, useEffect } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Redirect, Route } from 'react-router-dom'
import { LOCAL_STORAGE_AUTH_TOKEN_KEY } from '~/alpha/core'
import TopBar from '~/components/layout/TopBar'
import { SchoolPopupModal } from '~/components/modal/SchoolPopup'
import { useEmailPhoneStore } from '~/context/user'

/**
 * For school app only!
 */
export const PrivateRoute = ({
  component: Component,
  ...rest
}: {
  component: FC<any>
  path: string
}) => {
  const authToken = window.localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)
  const isLogout = !authToken

  const { phone, email, loadingMe } = useEmailPhoneStore()
  const isNotAllowedToUse =
    loadingMe === false &&
    (!phone || !email) &&
    rest.path !== '/setting' &&
    rest.path !== '/logout'

  const { t } = useTranslation()

  useEffect(() => {
    if (isNotAllowedToUse && !isLogout) {
      toast.error(
        t('学校メールアドレスと学校代表者電話番号が登録されていません。'),
      )
    }
  }, [isNotAllowedToUse, isLogout, t])

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLogout && rest.path !== '/') {
          // keep this log
          console.log('Not found auth token, redirect to login page!')

          return (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
        }

        if (isNotAllowedToUse) {
          return <Redirect to={{ pathname: '/setting' }} />
        }

        return (
          <>
            <TopBar />
            <Component {...props} />
            <SchoolPopupModal path={rest.path} />
          </>
        )
      }}
    />
  )
}
