import { CloseOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePopupStore } from '~/context/popup'

export const SchoolPopupModal = ({ path }: { path: string }) => {
  const [showModal, setShowModal] = useState(false)
  const [contentId, setContentId] = useState(0)
  const { popups, readIds, setReadIds } = usePopupStore()
  const { t } = useTranslation()

  const prefPath = useRef('')

  const showablePopups: {
    _id: string
    content: string
    createdAt: Date
    readAt?: Date | undefined
  }[] = popups?.filter(({ _id }) => !readIds.includes(_id)) ?? []

  useEffect(() => {
    if (contentId > showablePopups.length - 1) setShowModal(false)
  }, [contentId, showablePopups.length, setShowModal])

  useEffect(() => {
    if (prefPath.current !== path && popups.length) {
      setShowModal(!!showablePopups.length)
      prefPath.current = path
    }
  }, [path, popups.length, setShowModal, showablePopups.length])

  const handleCancel = () => {
    if (contentId < showablePopups.length - 1) {
      setContentId(contentId + 1)
    } else {
      setShowModal(false)
    }
  }

  const handleConfirm = () => {
    const record = showablePopups[contentId]
    if (!readIds.includes(record._id)) {
      setReadIds([...readIds, record._id])
    }
    if (contentId === showablePopups.length - 1) setShowModal(false)
  }

  // SchoolPopupModal
  const SchoolPopupModalProps = {
    bodyStyle: { borderRadius: '10px' },
    width: '800px',
    visible: showModal,
    onCancel: handleCancel,
    forceRender: true,
    closeIcon: <CloseOutlined className="text-2xl color-blue-f" />,
    footer: [
      <Button key="submit" type="primary" onClick={handleConfirm}>
        {t('確認_')}
      </Button>,
    ],
  }

  return (
    <>
      {showablePopups.length > 0 && (
        <Modal {...SchoolPopupModalProps}>
          <div className="whitespace-pre-wrap">
            {showablePopups[contentId]?.content}
          </div>
        </Modal>
      )}
    </>
  )
}
