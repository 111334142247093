import { Rule } from 'antd/lib/form'
import { TFunction } from 'i18next'

const getOneDecimalPlaceRule = (t: TFunction<'translation', undefined>) => ({
  pattern: /^\d+(?:\.\d{1})?$/,
  message: t('小数点の入力は第1位までです'),
})

const getIntegerRule = (t: TFunction<'translation', undefined>) => ({
  pattern: /^\d+$/,
  message: t('小数点の入力はできません'),
})

export interface InputConfigItem {
  label: string
  name: string
  suffix: string
  min: number
  max: number
  rules: [
    {
      type: 'number' | 'string'
      min: number
      max: number
      message: string
    },
    { pattern: RegExp; message: string },
  ]
}

export interface InputConfig {
  key: string
  tab: string
  measurable: true
  tab_width?: string
  items: InputConfigItem[]
}

/**
 * Grip:
 * 握力の計算式について
 * 左右良い方の記録を小数点切り捨て
 * 握力平均＝（左の良い方＋右の良い方）/2
 * ※握力平均が小数点の場合は繰り上げ
 *
 * 例）
 * 左1回目：10.4
 * 左2回目：11.8
 * 右1回目：9
 * 右2回目：12.7
 *
 * 左の良い方：11
 * 右の良い方：12
 *
 * 握力平均＝(11+12)/2=11.5=12
 * ※Excelでの一括アップロード時も入力範囲外の数値がある場合アラートを出すようにしたい
 *
 */
export const getInputListOfTestRes = (t: TFunction<'translation', undefined>) =>
  [
    {
      key: 'grip',
      tab: t('握力'),
      measurable: true,
      items: [
        {
          label: t('右(1回目)'),
          name: 'gripRight1',
          suffix: 'kg',
          min: 0,
          max: 99,
          rules: [
            {
              type: 'number',
              min: 0,
              max: 99,
              message: t('{{min}}から{{max}}の数字を入力してください', {
                min: 0,
                max: 99,
              }),
            },
            getOneDecimalPlaceRule(t),
          ],
        },
        {
          label: t('右(2回目)'),
          name: 'gripRight2',
          suffix: 'kg',
          min: 0,
          max: 99,
          rules: [
            {
              type: 'number',
              min: 0,
              max: 99,
              message: t('{{min}}から{{max}}の数字を入力してください', {
                min: 0,
                max: 99,
              }),
            },
            getOneDecimalPlaceRule(t),
          ],
        },
        {
          label: t('左(1回目)'),
          name: 'gripLeft1',
          suffix: 'kg',
          min: 0,
          max: 99,
          rules: [
            {
              type: 'number',
              min: 0,
              max: 99,
              message: t('{{min}}から{{max}}の数字を入力してください', {
                min: 0,
                max: 99,
              }),
            },
            getOneDecimalPlaceRule(t),
          ],
        },
        {
          label: t('左(2回目)'),
          name: 'gripLeft2',
          suffix: 'kg',
          min: 0,
          max: 99,
          rules: [
            {
              type: 'number',
              min: 0,
              max: 99,
              message: t('{{min}}から{{max}}の数字を入力してください', {
                min: 0,
                max: 99,
              }),
            },
            getOneDecimalPlaceRule(t),
          ],
        },
      ],
    },
    {
      key: 'sitUps',
      tab: t('上体起こし'),
      measurable: true,
      items: [
        {
          name: 'sitUps',
          suffix: '回',
          min: 0,
          max: 70,
          rules: [
            {
              type: 'number',
              min: 0,
              max: 70,
              message: t('{{min}}から{{max}}の数字を入力してください', {
                min: 0,
                max: 70,
              }),
            },
            getIntegerRule(t),
          ],
        },
      ],
    },
    {
      key: 'bending',
      tab: t('長座体前屈'),
      measurable: true,
      items: [
        {
          label: `${t('（')}${t('1回目_test')})${t('）')}`,
          name: 'bending1',
          suffix: 'cm',
          min: 1,
          max: 99,
          rules: [
            {
              type: 'number',
              min: 1,
              max: 99,
              message: t('{{min}}から{{max}}の数字を入力してください', {
                min: 1,
                max: 99,
              }),
            },
            getIntegerRule(t),
          ],
        },
        {
          label: `${t('（')}${t('2回目_test')})${t('）')}`,
          name: 'bending2',
          suffix: 'cm',
          min: 1,
          max: 99,
          rules: [
            {
              type: 'number',
              min: 1,
              max: 99,
              message: t('{{min}}から{{max}}の数字を入力してください', {
                min: 1,
                max: 99,
              }),
            },
            getIntegerRule(t),
          ],
        },
      ],
    },
    {
      key: 'sideJump',
      tab: t('反復横跳び'),
      measurable: true,
      items: [
        {
          label: `${t('（')}${t('1回目_test')})${t('）')}`,
          name: 'sideJump1',
          suffix: t('回'),
          min: 1,
          max: 99,
          rules: [
            {
              type: 'number',
              min: 1,
              max: 99,
              message: t('{{min}}から{{max}}の数字を入力してください', {
                min: 1,
                max: 99,
              }),
            },
            getIntegerRule(t),
          ],
        },
        {
          label: `${t('（')}${t('2回目_test')})${t('）')}`,
          name: 'sideJump2',
          suffix: t('回'),
          min: 1,
          max: 99,
          rules: [
            {
              type: 'number',
              min: 1,
              max: 99,
              message: t('{{min}}から{{max}}の数字を入力してください', {
                min: 1,
                max: 99,
              }),
            },
            getIntegerRule(t),
          ],
        },
      ],
    },
    {
      key: 'shuttleRun',
      tab: t('20mシャトルラン'),
      tab_width: 'w-24',
      measurable: true,
      items: [
        {
          name: 'shuttleRunCount',
          suffix: t('回'),
          min: 0,
          max: 247,
          rules: [
            {
              type: 'number',
              min: 0,
              max: 247,
              message: t('{{min}}から{{max}}の数字を入力してください', {
                min: 1,
                max: 247,
              }),
            },
            getIntegerRule(t),
          ],
        },
      ],
    },
    {
      key: 'enduranceRun',
      tab: t('持久走'),
      measurable: true,
      items: [
        {
          name: 'runningTime',
          suffix: t('分'),
          rules: [
            {
              type: 'number',
              min: 1,
              max: 30,
              message: t('{{min}}から{{max}}の数字を入力してください', {
                min: 1,
                max: 30,
              }),
            },
            getIntegerRule(t),
          ],
        },
        {
          name: 'runningTimeSeconds',
          suffix: t('秒'),
          rules: [
            {
              type: 'number',
              min: 0,
              max: 59,
              message: t('{{min}}から{{max}}の数字を入力してください', {
                min: 0,
                max: 59,
              }),
            },
            getIntegerRule(t),
            ({ getFieldValue }) => ({
              validator(_: unknown, value: null | number | undefined) {
                if (value == null && getFieldValue('runningTime')) {
                  return Promise.reject(new Error(t('入力されていません。')))
                }
                return Promise.resolve()
              },
            }),
          ],
        },
      ],
    },
    {
      key: 'sprintRun',
      tab: t('50m走'),
      measurable: true,
      items: [
        {
          name: 'sprintRunSeconds',
          suffix: t('秒'),
          min: 5,
          max: 60,
          rules: [
            {
              type: 'number',
              min: 5,
              max: 60,
              message: t('{{min}}から{{max}}の数字を入力してください', {
                min: 5,
                max: 60,
              }),
            },
            getOneDecimalPlaceRule(t),
          ],
        },
      ],
    },
    {
      key: 'standingJump',
      tab: t('立ち幅跳び'),
      measurable: true,
      items: [
        {
          label: `${t('（')}${t('1回目_test')})${t('）')}`,
          name: 'standingJump1',
          suffix: 'cm',
          min: 1,
          max: 399,
          rules: [
            {
              type: 'number',
              min: 1,
              max: 399,
              message: t('{{min}}から{{max}}の数字を入力してください', {
                min: 1,
                max: 399,
              }),
            },
            getIntegerRule(t),
          ],
        },
        {
          label: `${t('（')}${t('2回目_test')})${t('）')}`,
          name: 'standingJump2',
          suffix: 'cm',
          min: 1,
          max: 399,
          rules: [
            {
              type: 'number',
              min: 1,
              max: 399,
              message: t('{{min}}から{{max}}の数字を入力してください', {
                min: 1,
                max: 399,
              }),
            },
            getIntegerRule(t),
          ],
        },
      ],
    },
    {
      key: 'handballThrow',
      tab: t('ボール投げ'),
      measurable: true,
      items: [
        {
          label: `${t('（')}${t('1回目_test')})${t('）')}`,
          name: 'handballThrow1',
          suffix: 'm',
          min: 0,
          max: 99,
          rules: [
            {
              type: 'number',
              min: 0,
              max: 99,
              message: t('{{min}}から{{max}}の数字を入力してください', {
                min: 0,
                max: 99,
              }),
            },
            getIntegerRule(t),
          ],
        },
        {
          label: `${t('（')}${t('2回目_test')})${t('）')}`,
          name: 'handballThrow2',
          suffix: 'm',
          min: 0,
          max: 99,
          rules: [
            {
              type: 'number',
              min: 0,
              max: 99,
              message: t('{{min}}から{{max}}の数字を入力してください', {
                min: 0,
                max: 99,
              }),
            },
            getIntegerRule(t),
          ],
        },
      ],
    },
  ] as const

export const getNoEnduranceRunInputList = (
  t: TFunction<'translation', undefined>,
) => getInputListOfTestRes(t).filter((input) => input.key !== 'enduranceRun')

export const listInputEdit = [
  'weight',
  'height',
  'gripRight1',
  'gripRight2',
  'gripLeft1',
  'gripLeft2',
  'sitUps',
  'bending1',
  'bending2',
  'sideJump1',
  'sideJump2',
  'shuttleRunCount',
  'sprintRunSeconds',
  'standingJump1',
  'standingJump2',
  'handballThrow1',
  'handballThrow2',
  'runningTime',
  'runningTimeSeconds',
] as const

export const weightRules = (t: TFunction<'translation', undefined>): Rule[] => [
  {
    type: 'number',
    min: 10,
    max: 150,
    message: t('{{min}}から{{max}}の数字を入力してください', {
      min: 10,
      max: 150,
    }),
  },
  getOneDecimalPlaceRule(t),
]

export const heightRules = (t: TFunction<'translation', undefined>): Rule[] => [
  {
    type: 'number',
    min: 80,
    max: 220,
    message: t('{{min}}から{{max}}の数字を入力してください', {
      min: 80,
      max: 220,
    }),
  },
  getOneDecimalPlaceRule(t),
]

export const getInputRulesForTestRes = (
  t: TFunction<'translation', undefined>,
) => ({
  weight: weightRules(t),
  height: heightRules(t),
  gripRight1: [
    {
      type: 'number',
      min: 0,
      max: 99,
      message: t('{{min}}から{{max}}の数字を入力してください', {
        min: 0,
        max: 99,
      }),
    },
    getOneDecimalPlaceRule(t),
  ],
  gripRight2: [
    {
      type: 'number',
      min: 0,
      max: 99,
      message: t('{{min}}から{{max}}の数字を入力してください', {
        min: 0,
        max: 99,
      }),
    },
    getOneDecimalPlaceRule(t),
  ],
  gripLeft1: [
    {
      type: 'number',
      min: 0,
      max: 99,
      message: t('{{min}}から{{max}}の数字を入力してください', {
        min: 0,
        max: 99,
      }),
    },
    getOneDecimalPlaceRule(t),
  ],
  gripLeft2: [
    {
      type: 'number',
      min: 0,
      max: 99,
      message: t('{{min}}から{{max}}の数字を入力してください', {
        min: 0,
        max: 99,
      }),
    },
    getOneDecimalPlaceRule(t),
  ],
  sitUps: [
    {
      type: 'number',
      min: 0,
      max: 70,
      message: t('{{min}}から{{max}}の数字を入力してください', {
        min: 0,
        max: 70,
      }),
    },
    getIntegerRule(t),
  ],
  bending1: [
    {
      type: 'number',
      min: 1,
      max: 99,
      message: t('{{min}}から{{max}}の数字を入力してください', {
        min: 1,
        max: 99,
      }),
    },
    getIntegerRule(t),
  ],
  bending2: [
    {
      type: 'number',
      min: 1,
      max: 99,
      message: t('{{min}}から{{max}}の数字を入力してください', {
        min: 1,
        max: 99,
      }),
    },
    getIntegerRule(t),
  ],
  sideJump1: [
    {
      type: 'number',
      min: 1,
      max: 99,
      message: t('{{min}}から{{max}}の数字を入力してください', {
        min: 1,
        max: 99,
      }),
    },
    getIntegerRule(t),
  ],
  sideJump2: [
    {
      type: 'number',
      min: 1,
      max: 99,
      message: t('{{min}}から{{max}}の数字を入力してください', {
        min: 1,
        max: 99,
      }),
    },
    getIntegerRule(t),
  ],
  shuttleRunCount: [
    {
      type: 'number',
      min: 0,
      max: 247,
      message: t('{{min}}から{{max}}の数字を入力してください', {
        min: 0,
        max: 247,
      }),
    },
    getIntegerRule(t),
  ],
  sprintRun: [
    {
      type: 'number',
      min: 0,
      max: 59,
      message: t('{{min}}から{{max}}の数字を入力してください', {
        min: 0,
        max: 59,
      }),
    },
    getOneDecimalPlaceRule(t),
  ],
  standingJump1: [
    {
      type: 'number',
      min: 1,
      max: 399,
      message: t('{{min}}から{{max}}の数字を入力してください', {
        min: 1,
        max: 399,
      }),
    },
    getIntegerRule(t),
  ],
  standingJump2: [
    {
      type: 'number',
      min: 1,
      max: 399,
      message: t('{{min}}から{{max}}の数字を入力してください', {
        min: 1,
        max: 399,
      }),
    },
    getIntegerRule(t),
  ],
  handballThrow1: [
    {
      type: 'number',
      min: 0,
      max: 99,
      message: t('{{min}}から{{max}}の数字を入力してください', {
        min: 0,
        max: 99,
      }),
    },
    getIntegerRule(t),
  ],
  handballThrow2: [
    {
      type: 'number',
      min: 0,
      max: 99,
      message: t('{{min}}から{{max}}の数字を入力してください', {
        min: 0,
        max: 99,
      }),
    },
    getIntegerRule(t),
  ],
  runningTime: [
    {
      type: 'number',
      min: 1,
      max: 30,
      message: t('{{min}}から{{max}}の数字を入力してください', {
        min: 1,
        max: 30,
      }),
    },
    getIntegerRule(t),
  ],
  runningTimeSeconds: [
    {
      type: 'number',
      min: 0,
      max: 59,
      message: t('{{min}}から{{max}}の数字を入力してください', {
        min: 0,
        max: 59,
      }),
    },
    getIntegerRule(t),
    ({ getFieldValue }) => ({
      validator(_: unknown, value: null | number | undefined) {
        if (value == null && getFieldValue('runningTime')) {
          return Promise.reject(new Error(t('入力されていません。')))
        }
        return Promise.resolve()
      },
    }),
  ],
  sprintRunSeconds: [
    {
      type: 'number',
      min: 5,
      max: 60,
      message: t('{{min}}から{{max}}の数字を入力してください', {
        min: 5,
        max: 60,
      }),
    },
    getOneDecimalPlaceRule(t),
  ],
})
