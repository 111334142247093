import './ReportMenu.css'

import { Button } from 'antd'
import { TFunction } from 'i18next'
import type { Dispatch, SetStateAction } from 'react'

export type TReportMenuIndex = null | 1 | 2 | 3 | 4 | 5 | 6

interface Props {
  reportIndex: TReportMenuIndex
  setReportIndex: Dispatch<SetStateAction<TReportMenuIndex>>
  t: TFunction<'translation', undefined>
  handlePrint: () => void
}

const SmallNextIcon = (
  <img
    src="next_icon.png"
    alt=""
    style={{ width: 8.3, height: 14.5, marginTop: 20 }}
  />
)

/**
 * Report menu component for `./test_report`
 */
const ReportMenu = ({ reportIndex, setReportIndex, t, handlePrint }: Props) => {
  return (
    <div className={reportIndex ? 'hidden' : 'block'}>
      <div
        className="grid-cols-3 grid mt-14 gap-4"
        style={{ color: 'black', width: 920, margin: '100px auto 40px' }}
      >
        <div className="flex cursor-pointer">
          <div
            className="rounded-md report-menu-card"
            onClick={() => setReportIndex(1)}
          >
            <div className="flex justify-between">
              <img
                src="report1.png"
                alt=""
                style={{ width: 54, height: 54, marginBottom: 9 }}
              />
              {SmallNextIcon}
            </div>
            <div className="mb-8">
              <span style={{ fontSize: 20, fontWeight: 700 }}>
                {t('学年平均値及び標準偏差')}
              </span>
            </div>
          </div>
        </div>

        <div className="flex cursor-pointer">
          <div
            className="rounded-md report-menu-card"
            onClick={() => setReportIndex(2)}
          >
            <div className="flex justify-between">
              <img
                src="report2.png"
                alt=""
                style={{ width: 54, height: 54, marginBottom: 9 }}
              />
              {SmallNextIcon}
            </div>
            <div className="mb-8">
              <span style={{ fontSize: 20, fontWeight: 700 }}>
                {t('総合評価')}
              </span>
            </div>
          </div>
        </div>

        <div className="flex cursor-pointer">
          <div
            className="rounded-md report-menu-card"
            onClick={() => setReportIndex(3)}
          >
            <div className="flex justify-between">
              <img
                src="report3.png"
                alt=""
                style={{ width: 42, height: 54, marginBottom: 9 }}
              />
              {SmallNextIcon}
            </div>
            <div className="mb-8">
              <span style={{ fontSize: 20, fontWeight: 700 }}>
                {t('アンケート')}
              </span>
            </div>
          </div>
        </div>
        <div className="flex mt-2 cursor-pointer">
          <div
            className="rounded-md report-menu-card"
            onClick={() => setReportIndex(6)}
          >
            <div className="flex justify-between">
              <img
                src="report4.png"
                alt=""
                style={{ width: 54, height: 54, marginBottom: 9 }}
              />
              {SmallNextIcon}
            </div>
            <div className="mb-8">
              <span style={{ fontSize: 20, fontWeight: 700 }}>
                {t('全国との比較')}
              </span>
            </div>
          </div>
        </div>

        <div className="flex mt-2 cursor-pointer">
          <div
            className="rounded-md report-menu-card"
            onClick={() => setReportIndex(4)}
          >
            <div className="flex justify-between">
              <img
                src="report5.png"
                alt=""
                style={{ width: 54, height: 54, marginBottom: 9 }}
              />
              {SmallNextIcon}
            </div>
            <div className="mb-8">
              <span style={{ fontSize: 20, fontWeight: 700 }}>
                {t('クラス平均値')}
              </span>
            </div>
          </div>
        </div>
        <div className="flex mt-2 cursor-pointer">
          <div
            className="rounded-md report-menu-card"
            onClick={() => setReportIndex(5)}
          >
            <div className="flex justify-between">
              <img
                src="report6.png"
                alt=""
                style={{ width: 54, height: 54, marginBottom: 9 }}
              />
              {SmallNextIcon}
            </div>
            <div className="mb-8">
              <span style={{ fontSize: 20, fontWeight: 700 }}>
                {t('部活動平均値')}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <Button type="primary" className="w-40" onClick={handlePrint}>
          {t('印刷')}
        </Button>
      </div>
    </div>
  )
}

ReportMenu.propTypes = {}

export default ReportMenu
