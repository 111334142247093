import './VideoModal.css'

import { CloseOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import { memo } from 'react'
import { isMobile } from 'react-device-detect'
import { useAlphaStore } from '~/context'
import Video from '../cards/Video'

interface VideoModalProps {
  video: {
    url: string
    lowUrl: string
    thumb: string
  }
  videoType: string
  keyName: string
  onClose: () => void
}

export const VideoModal = memo(
  ({ video, onClose, videoType, keyName }: VideoModalProps) => {
    const ratio = 0.67
    const { school } = useAlphaStore()

    if (!school) {
      return null
    }

    return (
      <Modal
        visible={true}
        onCancel={onClose}
        footer={null}
        centered
        width="auto"
        closeIcon={
          <CloseOutlined
            className="absolute -top-8 -right-1 text-2xl opacity-80 font-bold hover:opacity-100"
            style={{ color: '#FFF' }}
          />
        }
        className="video-modal"
      >
        <Video
          src={school.attributes.videoQualitySetting ? video.lowUrl : video.url}
          size={{
            width: window.screen.width * ratio,
            height: window.screen.height * ratio,
          }}
          thumb={video.thumb}
          videoType={videoType}
          keyName={keyName}
          className="rounded-none"
          autoPlay={!isMobile}
          isAutoPlayManually
        />
      </Modal>
    )
  },
)
