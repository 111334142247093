import { Button, Col, Form, Input, Row, Steps, Typography } from 'antd'
import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { AuthContext, useTeacherLinkSchoolMutation } from '@alpha/core'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import CompletionLayout from '~/components/layout/CompletionLayout'
import { useFirstTimeStore } from '~/context/is-first-time-login'
import { ITeacher } from '~/types/api/alpha/teachers'
import Api from '~/utils/api'
import isJapanese from '~/utils/isJapanese'
import { SignInLayout } from '../layout/SignInLayout'

// WARN: When using English, given names and family names order is reversed,
// so the family name (Last Name) is saved in the given name (First Name) in DB.

const { Step } = Steps
const { Text } = Typography

interface ICodeSubmitTeacherInfo {
  familyName: string
  familyNameHiragana: string | undefined
  givenName: string
  givenNameHiragana: string | undefined
  email: string
}

interface ICodeSubmitTeacherInfoInput extends ICodeSubmitTeacherInfo {
  invitationCode: string
}

const RegisterUserPage = () => {
  const [registerComplete, setRegisterComplete] = useState(false)
  const [currentStepIdx, setCurrentStepIdx] = useState(0)
  const [attributes, setAttributes] = useState<ICodeSubmitTeacherInfo>({
    familyName: '',
    familyNameHiragana: '',
    givenName: '',
    givenNameHiragana: '',
    email: '',
  })

  const { setIsFirstTimeLogin } = useFirstTimeStore()

  const auth = useContext(AuthContext)
  const history = useHistory()
  const { t, i18n } = useTranslation()

  const [linkSchool, { loading }] = useTeacherLinkSchoolMutation()

  const isUsingJp = isJapanese(i18n)

  const onProfileSubmit = async (data: ICodeSubmitTeacherInfoInput) => {
    setAttributes({
      familyName: data.familyName,
      familyNameHiragana: data.familyNameHiragana ?? '',
      givenName: data.givenName,
      givenNameHiragana: data.givenNameHiragana ?? '',
      email: data.email,
    })
    setCurrentStepIdx(1)
  }

  const onCodeSubmit = async (data: ICodeSubmitTeacherInfoInput) => {
    const variables = {
      input: {
        attributes,
        invitationCode: data.invitationCode.trim(),
      },
    }
    try {
      const res = await linkSchool({ variables })
      if (res.errors) {
        toast.error(`${t('エラーが発生しました。')} [${res.errors.join(',')}]`)
        return
      }

      const teacherLinkSchool = res.data?.teacherLinkSchool

      if (!teacherLinkSchool?.token) {
        toast.error(t('エラーが発生しました。\n招待コードを確認してください。'))
        console.error('token is required in response!')

        return
      }

      auth.setAccessToken(teacherLinkSchool?.token)
      setRegisterComplete(true)
      setIsFirstTimeLogin(true)

      setTimeout(async () => {
        const { data }: { data: ITeacher } = await Api.get(
          '/alpha/v1/school/me',
        )
        if (
          data.school?.attributes.email &&
          data?.school?.attributes?.telephone
        )
          history.push('/home')
        else history.push('/setting')
      }, 2000)
    } catch (err) {
      toast.error(t('エラーが発生しました。\n招待コードを確認してください。'))
      console.error('when linkSchool, error:', err)
    }
  }

  if (!auth.currentUser) {
    return null
  }

  const _steps = [
    {
      title: 'アカウント情報を登録',
      content: (
        <>
          <Form layout="vertical" onFinish={onProfileSubmit} hideRequiredMark>
            <div className="steps-content flex items-center justify-center">
              <div className="flex flex-col items-center justify-center">
                <span className="text-black font-black">
                  {t('アカウント情報を登録')}
                </span>
                <span className="text-black font-bold text-xs">
                  {t('アカウント情報を登録してください。')}
                </span>
                <div className="mt-12">
                  <Row gutter={8}>
                    <Col span={12}>
                      <Form.Item
                        name="familyName"
                        label={
                          <Text className="text-xs font-bold">{t('姓')}</Text>
                        }
                        rules={[
                          {
                            required: true,
                            message: t('姓を入力してください'),
                          },
                        ]}
                      >
                        <Input className="bg-gray-150" max={100} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="givenName"
                        label={
                          <Text className="text-xs font-bold">{t('名')}</Text>
                        }
                        rules={[
                          {
                            required: true,
                            message: t('名を入力してください'),
                          },
                        ]}
                      >
                        <Input className="bg-gray-150" max={100} />
                      </Form.Item>
                    </Col>
                  </Row>
                  {isUsingJp && (
                    <Row gutter={8}>
                      <Col span={12}>
                        <Form.Item
                          name="familyNameHiragana"
                          label={
                            <Text className="text-xs font-bold">
                              {t('せい')}
                            </Text>
                          }
                        >
                          <Input className="bg-gray-150" max={100} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="givenNameHiragana"
                          label={
                            <Text className="text-xs font-bold">
                              {t('めい')}
                            </Text>
                          }
                        >
                          <Input className="bg-gray-150" max={100} />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="email"
                        label={
                          <Text className="text-xs font-bold">
                            {t('メールアドレス')}
                          </Text>
                        }
                        rules={[
                          {
                            required: true,
                            message: t('メールアドレスを入力してください'),
                          },
                          {
                            type: 'email',
                            message: t('メールアドレスを入力してください'),
                          },
                        ]}
                      >
                        <Input className="bg-gray-150" max={100} />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <div>
              <div className="steps-action text-center">
                <Button
                  type="primary"
                  className="w-60"
                  size="large"
                  htmlType="submit"
                >
                  {t('NEXT')}
                </Button>
              </div>
            </div>
          </Form>
        </>
      ),
    },
    {
      title: '学校の招待コードを入力',
      content: (
        <>
          <Form layout="vertical" onFinish={onCodeSubmit} hideRequiredMark>
            <div className="steps-content flex items-center justify-center">
              <div className="flex flex-col items-center justify-center">
                <span className="text-black font-black">
                  {t('学校の招待コードを入力')}
                </span>
                <div className="mt-12">
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="invitationCode"
                        label={
                          <Text className="text-xs font-bold">
                            {t('招待コード')}
                          </Text>
                        }
                        rules={[
                          {
                            required: true,
                            message: t('招待コードを入力してください'),
                          },
                        ]}
                      >
                        <Input
                          className="bg-gray-150"
                          style={{ width: 560 }}
                          max={100}
                          disabled={loading}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <div>
              <div className="steps-action text-center" style={{ marginTop: -16 }}>
                <Button
                  type="primary"
                  className="w-60"
                  size="large"
                  htmlType="submit"
                  loading={loading}
                >
                  {t('signUp')}
                </Button>
              </div>
            </div>
          </Form>

          <div className="text-center font-bold text-black" style={{ paddingTop: 32 }}>
          昨年度からご利用頂いているお客様は、今年度の招待コードでログインしてください。<br/>
          年度更新に伴い、昨年度の招待コードではログインできません。<br/>
          <br/>
          お急ぎの場合や、ご不明点等ございましたら下記よりお問い合わせください。
          </div>

          <div className="mt-4 flex items-center justify-center">
            <Button
              className="w-60 rounded m-4"
              size="large"
              type="primary"
              onClick={() =>
                window.open('https://pestalozzi-tech.com/contact/', '_blank')
              }
            >
              {'問い合わせ'}
            </Button>
          </div>
        </>
      ),
    },
  ]

  return (
    <div className="min-h-screen bg-gray-100 py-10">
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        {!registerComplete ? (
          <div>
            <SignInLayout.AlphaLogo />
            <div className="flex flex-col items-center justify-center mt-16">
              <Steps
                className="m-auto"
                style={{ width: 350 }}
                labelPlacement="vertical"
                size="default"
                current={currentStepIdx}
              >
                {_steps.map((item) => (
                  <Step key={item.title} title={t(item.title)} />
                ))}
              </Steps>
              <div className="steps-content">
                {_steps[currentStepIdx].content}
              </div>
            </div>
          </div>
        ) : (
          <CompletionLayout message={t('ようこそ！')} />
        )}
      </div>
    </div>
  )
}

export default RegisterUserPage
