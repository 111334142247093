import {
  DownloadOutlined,
  UploadOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Select,
  Steps,
  Table,
  Typography,
  Upload,
} from 'antd'
import axios from 'axios'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { read, set_cptable, utils } from 'xlsx'
import * as cp_table from 'xlsx/dist/cpexcel.full.mjs'

import { ColumnsType } from 'antd/lib/table'
import type { RcFile } from 'antd/lib/upload'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router-dom'
import CompletionLayout from '~/components/layout/CompletionLayout'
import { useAlphaStore } from '~/context'
import { removeSpaceAndJpSpace } from '~/school-utils/name'
import { checkIfValidEmail, normalizeJpEmail } from '~/school-utils/validations'
import { currentYear, TUserGender } from '~/utils/constants'
import isJapanese from '~/utils/isJapanese'
import { getNonZeroPositiveNumberFromValue } from '~/utils/number'
import type { IBaseStudent, ICreatedStudents } from '~/utils/types/api/students'
import {
  getValidGenderFromJpText,
  getValidPositiveNumberFromExcelFile,
} from '~/utils/validations'
import { Dashboard } from '../layout/Dashboard'
import ScreenLoading from '../shared/loading/ScreenLoading'
import { tableColumns } from './student-list/components/table'

// WARN: When using English, given names and family names order is reversed,
// so the family name (Last Name) is saved in the given name (First Name) in DB.

const { Option } = Select
const { Step } = Steps
const { Text } = Typography

set_cptable(cp_table)

const displayNumbers = [
  { text: '20　件/ページ', value: 20 },
  { text: '50　件/ページ', value: 50 },
  { text: '100　件/ページ', value: 100 },
  { text: '1000　件/ページ', value: 1000 },
  { text: '2000　件/ページ', value: 2000 },
] as const

/**
 * Download from `./public` folder.
 *
 * @param isJapaneseLang
 * @param isElementarySchool
 */
const downloadTemplate = (
  isJapaneseLang: boolean,
  isElementarySchool: boolean,
) => {
  if (isJapaneseLang) {
    window.open(
      isElementarySchool
        ? '/upload_sample/ALPHAアップロード用ファイル-児童生徒登録（小学校）.xlsx'
        : '/upload_sample/ALPHAアップロード用ファイル-児童生徒登録（中高）.xlsx',
      '_blank',
    )
  } else {
    window.open(
      // TODO
      '/upload_sample/ALPHA Student Registration sheet.xlsx',
      '_blank',
    )
  }
}

type TStepIndex = 0 | 1 | 2 | 3

/**
 * Student batch upload Page.
 * Path: /student_list
 */
const StudentsBatchUploadPage = () => {
  const { teacher, school } = useAlphaStore()

  const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'

  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)
  const [uploading, setUploading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [createdStudents, setCreatedStudents] = useState<ICreatedStudents[]>([])

  const [registerComplete, setRegisterComplete] = useState(false)
  const [currentStepIdx, setCurrentStepIdx] = useState<TStepIndex>(0)
  const [confirmed1, setConfirmed1] = useState(false)
  const [confirmed2, setConfirmed2] = useState(false)
  const [confirmed3, setConfirmed3] = useState(false)
  const [pageSize, setPageSize] = useState(50)
  const [uploadFile, setUploadFile] = useState<RcFile | null>(null)

  const [mainErrMess, setMainErrMess] = useState<string | false>(false)
  const [duplicatedSsoEmails, setDuplicatedSsoEmails] = useState<
    Set<string> | undefined
  >()

  const history = useHistory()
  const { t, i18n } = useTranslation()
  const isUsingJp = isJapanese(i18n)

  useEffect(() => {
    switch (currentStepIdx) {
      case 0:
        setNextBtnDisabled(false)
        break
      case 1:
        setNextBtnDisabled(!confirmed1)
        break
      case 2:
        setNextBtnDisabled(!confirmed2)
        break
      case 3:
        setNextBtnDisabled(!confirmed3)
        break
    }
  }, [currentStepIdx, confirmed1, confirmed2, confirmed3])

  const onFinish = useCallback(async (): Promise<boolean> => {
    if (!teacher) {
      toast.error(`${t('エラーが発生しました。')} [teacher is not found]`)
      return false
    }

    if (!school) {
      toast.error(`${t('エラーが発生しました。')} [school is not found]`)
      console.error('when call alpha/file-upload, error: school is not found')
      return false
    }

    // validate SSO emails
    // const uniqueEmails = new Set<string>()
    // const inputDuplicatedSsoEmails = new Set<string>()
    // let isShowedToast = false

    // const ssoEmailStudents: ({
    //   ssoEmail: string
    // } & IBaseStudent)[] = []

    // for (let i = 1; i <= createdStudents.length; i++) {
    //   const student = createdStudents[i - 1]
    //   const ssoEmail = student.ssoEmail

    //   if (ssoEmail) {
    //     // validated at the step before
    //     if (uniqueEmails.has(ssoEmail)) {
    //       // no show toast message at this step
    //       console.error(
    //         `row ${i - 1} has duplicated email! row:`,
    //         JSON.stringify(student),
    //       )

    //       const { schoolGrade, schoolClass, schoolAttendanceNumber } = student

    //       inputDuplicatedSsoEmails.add(ssoEmail)

    //       if (!isShowedToast) {
    //         toast.error(
    //           `${t(
    //             'ご入力いただいた以下の{生徒}のメールアドレスは既に登録されています。',
    //             {
    //               student: isElementarySchool ? '児童' : '生徒',
    //             },
    //           )}\n　　　　　　${schoolGrade}年${schoolClass}組${schoolAttendanceNumber}番`,
    //         )

    //         isShowedToast = true
    //       }
    //     } else {
    //       ssoEmailStudents.push({
    //         ssoEmail,
    //         schoolGrade: student.schoolGrade,
    //         schoolClass: student.schoolClass,
    //         schoolAttendanceNumber: student.schoolAttendanceNumber,
    //       })
    //     }

    //     uniqueEmails.add(ssoEmail)
    //   }
    // }

    setUploading(true)

    // upload backup file
    const schoolId = school._id
    const schoolName = school.attributes.schoolName

    /**
     * Determine whether when this file is uploaded.
     */
    let fileId = new Date().toISOString().split('T')[0]
    fileId = `student_list/${schoolName}_${schoolId}_${fileId}`

    const studentListFormData = new FormData()
    studentListFormData.append('file', uploadFile as Blob)
    studentListFormData.append('filename', fileId)

    let fileUrl: string

    try {
      const fileRes = await axios.post(
        `${process.env.REACT_APP_REST_API_URL}/alpha/file-upload`,
        studentListFormData,
      )

      fileUrl = fileRes.data.data

      // upload students data
      try {
        const res = await axios({
          method: 'POST',
          url: `${process.env.REACT_APP_REST_API_URL}/alpha/v1/school/up-students`,
          data: {
            filename: fileUrl,
            students: JSON.stringify(createdStudents),
          },
        })

        const { error } = res.data

        if (error) {
          toast.error(
            `${t('エラーが発生しました。')} [${JSON.stringify(error)}]`,
          )
        } else {
          toast.success(t('アップロード完了'))
          // form.resetFields()
          // reset created students
          setCreatedStudents([])
          setUploadFile(null)
          setTimeout(() => {
            setUploading(false)
          }, 1000)
        }
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      } catch (err: any) {
        const errRes = err.response as {
          data: {
            error?: string
            duplicatedKid?: IBaseStudent
            duplicateEmail?: string
          }
        }
        console.error('Error code uploading students:', errRes?.data?.error)

        // Let the interceptor handle the error message
        if (
          // errRes?.status === 409 &&
          errRes?.data?.error === 'SSO_EMAIL_ALREADY_EXISTS' &&
          errRes.data.duplicatedKid
        ) {
          setMainErrMess(
            t('重複したメールアドレスを確認して修正してください。'),
          )

          const { schoolAttendanceNumber, schoolClass, schoolGrade } =
            errRes.data.duplicatedKid

          toast.error(
            `${t(
              'ご入力いただいた以下の{生徒}のメールアドレスは既に登録されています。',
              {
                student: isElementarySchool ? '児童' : '生徒',
              },
            )}\n　　　　　　${schoolGrade}年${schoolClass}組${schoolAttendanceNumber}番`,
          )

          console.error(
            'errRes.data.duplicateEmail: ',
            errRes.data.duplicateEmail,
          )

          // Set the duplicated email to highlight in the table
          if (errRes.data.duplicateEmail) {
            setDuplicatedSsoEmails(new Set([errRes.data.duplicateEmail]))
          }
        }

        throw err
      }
    } catch (err) {
      return false
    } finally {
      setUploading(false)
    }

    return true
  }, [teacher, school, uploadFile, t, createdStudents, isElementarySchool])

  const changePageSize = (value: number) => {
    setPageSize(value)
  }

  const register = async () => {
    const isSuccess = await onFinish()
    if (isSuccess) {
      setRegisterComplete(true)
    }
  }

  const next = async () => {
    const nextStepIdx = currentStepIdx + 1
    if (nextStepIdx === 3 && !uploadFile) {
      toast.error(t('Excelファイルを選んでください。'))
      return
    }

    setCurrentStepIdx(nextStepIdx as TStepIndex)
  }

  const nextToStep4 = async () => {
    setCurrentStepIdx((currentStepIdx + 1) as TStepIndex)

    // if (duplicatedSsoEmailStudentInfo !== undefined) {
    //   console.log(
    //     '💚 ~ nextToStep4 ~ duplicatedSsoEmailStudentInfo:',
    //     duplicatedSsoEmailStudentInfo,
    //   )
    //   alert('nextToStep4')
    //   const { schoolAttendanceNumber, schoolClass, schoolGrade } =
    //     duplicatedSsoEmailStudentInfo

    //   toast.error(
    //     `${t(
    //       'ご入力いただいた以下の{生徒}のメールアドレスは既に登録されています。',
    //       {
    //         student: isElementarySchool ? '児童' : '生徒',
    //       },
    //     )}\n　　　　　　${schoolGrade}年${schoolClass}組${schoolAttendanceNumber}番`,
    //   )
    // }

    // validate SSO emails, after step 3
    const uniqueEmails = new Set<string>()
    const inputDuplicatedSsoEmails = new Set<string>()
    let isShowedToast = false
    let isSsoEmailsValid = true

    const ssoEmailStudents: ({
      ssoEmail: string
    } & IBaseStudent)[] = []

    for (let i = 1; i <= createdStudents.length; i++) {
      const student = createdStudents[i - 1]
      const ssoEmail = student.ssoEmail

      if (ssoEmail) {
        // validated at the step before
        if (uniqueEmails.has(ssoEmail)) {
          // no show toast message at this step
          console.error(
            `row ${i - 1} has duplicated email! row:`,
            JSON.stringify(student),
          )

          const duplicatedKids = createdStudents.filter(
            (kid) => kid.ssoEmail === ssoEmail,
          )

          let kidsInfoText = ''
          duplicatedKids.forEach((kid) => {
            const { schoolGrade, schoolClass, schoolAttendanceNumber } = kid
            kidsInfoText += `\n　　　　　　${schoolGrade}年${schoolClass}組${schoolAttendanceNumber}番,`
          })

          inputDuplicatedSsoEmails.add(ssoEmail)

          if (!isShowedToast) {
            toast.error(
              `${t(
                'ご入力いただいた以下の{生徒}のメールアドレスは既に登録されています。',
                {
                  student: isElementarySchool ? '児童' : '生徒',
                },
              )}${kidsInfoText}`,
            )

            isShowedToast = true
            isSsoEmailsValid = false
          }

          setMainErrMess(
            t('重複したメールアドレスを確認して修正してください。'),
          )
        } else {
          ssoEmailStudents.push({
            ssoEmail,
            schoolGrade: student.schoolGrade,
            schoolClass: student.schoolClass,
            schoolAttendanceNumber: student.schoolAttendanceNumber,
          })
        }

        uniqueEmails.add(ssoEmail)
      }
    }

    if (inputDuplicatedSsoEmails.size > 0) {
      setMainErrMess(t('重複したメールアドレスを確認して修正してください。'))
      setDuplicatedSsoEmails(inputDuplicatedSsoEmails)
    }

    if (ssoEmailStudents.length > 0 && isSsoEmailsValid) {
      setIsLoading(true)

      try {
        await axios.post<{
          isValid: boolean
        }>(
          `${process.env.REACT_APP_REST_API_URL}/alpha/v1/school/validate-sso-emails`,
          {
            students: ssoEmailStudents,
          },
        )
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      } catch (err: any) {
        isSsoEmailsValid = false
        const errRes = err.response as {
          data: {
            error?: string
            duplicatedKid?: IBaseStudent
            duplicateEmail?: string
          }
        }
        console.error('Error code uploading students:', errRes?.data?.error)

        // Let the interceptor handle the error message
        if (
          // errRes?.status === 409 &&
          errRes?.data?.error === 'SSO_EMAIL_ALREADY_EXISTS' &&
          errRes.data.duplicatedKid
        ) {
          setMainErrMess(
            t('重複したメールアドレスを確認して修正してください。'),
          )

          const { schoolAttendanceNumber, schoolClass, schoolGrade } =
            errRes.data.duplicatedKid

          toast.error(
            `${t(
              'ご入力いただいた以下の{生徒}のメールアドレスは既に登録されています。',
              {
                student: isElementarySchool ? '児童' : '生徒',
              },
            )}\n　　　　　　${schoolGrade}年${schoolClass}組${schoolAttendanceNumber}番`,
          )

          console.error(
            'errRes.data.duplicateEmail: ',
            errRes.data.duplicateEmail,
          )

          // Set the duplicated email to highlight in the table
          if (errRes.data.duplicateEmail) {
            setDuplicatedSsoEmails(new Set([errRes.data.duplicateEmail]))
          }
        }
        setIsLoading(false)

        return false
      }
      setIsLoading(false)
    }

    if (isSsoEmailsValid) {
      // reset error message shower
      if (duplicatedSsoEmails) {
        setDuplicatedSsoEmails(undefined)
      }

      if (mainErrMess) {
        setMainErrMess(false)
      }
    }
  }

  const prev = () => {
    setCurrentStepIdx((currentStepIdx - 1) as TStepIndex)
  }

  const parseXlsx = async (file: RcFile) => {
    const readData = read(await file.arrayBuffer())

    const sheetName = readData.SheetNames[0]
    const workBook = readData.Sheets

    if (sheetName && workBook) {
      const rows: Record<string, string | number | undefined | null>[] =
        utils.sheet_to_json(workBook[sheetName], {
          header: 1,
          skipHidden: true,
        })

      if (rows.length === 0) {
        toast.error(t('データがありません'))
        return
      }

      let errRowIdsString = ''

      const gradeKey = 0
      const classKey = 1
      const studentNumberKey = 2
      const familyNameKey = 3
      const givenNameKey = 4
      // WARN: When using English, given names and family names order is reversed,
      // so the family name (Last Name) is saved in the given name (First Name) in DB.
      const givenNameReadingKey = 5
      const familyNameReadingKey = 6
      const sexKey = 7
      const yearBirthKey = 8
      const monthBirthKey = 9
      const dayBirthKey = 10
      const ssoEmailKey = 11

      // if (isUsingJp) {
      //   gradeKey = '学年'
      //   classKey = '組'
      //   studentNumberKey = '出席番号'
      //   familyNameKey = '姓'
      //   givenNameKey = '名'
      //   givenNameReadingKey = 'せい'
      //   familyNameReadingKey = 'めい'
      //   sexKey = '性別'
      //   yearBirthKey = '生年月日（年）'
      //   monthBirthKey = '生年月日（月）'
      //   dayBirthKey = '生年月日（日）'
      // } else {
      //   gradeKey = 'Grade'
      //   classKey = 'Class'
      //   studentNumberKey = 'Number'

      //   familyNameKey = 'Given name'
      //   givenNameKey = 'Family name'
      //   givenNameReadingKey = 'せい'
      //   familyNameReadingKey = 'めい'
      //   sexKey = 'Sex'
      //   yearBirthKey = 'Date of Birth (year)'
      //   monthBirthKey = 'Date of Birth (month)'
      //   dayBirthKey = 'Date of Birth (date)'
      // }

      const _students: ICreatedStudents[] = []

      // const inputDuplicatedSsoEmails = new Set<string>()

      // const ssoEmailStudents: ({
      //   ssoEmail: string
      // } & IBaseStudent)[] = []

      // let isShowedToast = false
      // const uniqueEmails = new Set<string>()

      // data row start from 2, skip header
      for (let i = 2; i < rows.length; i++) {
        const row = rows[i]

        if (!row || row.length === 0) {
          continue
        }

        let familyName = row[familyNameKey]
        let givenName = row[givenNameKey]

        if (
          !familyName ||
          !givenName ||
          typeof familyName === 'object' ||
          typeof givenName === 'object'
        ) {
          console.error(`row ${i} has invalid name! row:`, JSON.stringify(row))
          errRowIdsString += `${i}、`
          continue
        }

        familyName = removeSpaceAndJpSpace(String(familyName))
        givenName = removeSpaceAndJpSpace(String(givenName))

        if (!familyName || !givenName) {
          console.error(`row ${i} has invalid name! row:`, JSON.stringify(row))
          errRowIdsString += `${i}、`
          continue
        }

        const schoolGradeNumber = getValidPositiveNumberFromExcelFile(
          row[gradeKey],
        )
        const schoolClassNumber = getValidPositiveNumberFromExcelFile(
          row[classKey],
        )
        const schoolAttendanceNumber = getValidPositiveNumberFromExcelFile(
          row[studentNumberKey],
        )

        if (
          !schoolGradeNumber ||
          !schoolClassNumber ||
          !schoolAttendanceNumber
        ) {
          console.error(
            `row ${i - 1} has invalid grade/class/attendance! row:`,
            JSON.stringify(row),
          )
          errRowIdsString += `${i - 1}、`
          continue
        }

        const gender = getValidGenderFromJpText(row[sexKey])

        if (!gender) {
          console.error(
            `row ${i - 1} has invalid gender! row:`,
            JSON.stringify(row),
          )
          errRowIdsString += `${i - 1}、`
          continue
        }

        const student: {
          familyName: string
          givenName: string
          familyNameHiragana: string
          givenNameHiragana: string
          gender: TUserGender
          yearBirth: number | undefined
          monthBirth: number | undefined
          dayBirth: number | undefined
          ssoEmail?: string
        } & IBaseStudent = {
          schoolGrade: schoolGradeNumber,
          schoolClass: schoolClassNumber,
          schoolAttendanceNumber,

          familyName,
          givenName,
          familyNameHiragana: row[givenNameReadingKey] as string,
          givenNameHiragana: row[familyNameReadingKey] as string,

          gender,
          yearBirth: getNonZeroPositiveNumberFromValue(row[yearBirthKey]),
          monthBirth: getNonZeroPositiveNumberFromValue(row[monthBirthKey]),
          dayBirth: getNonZeroPositiveNumberFromValue(row[dayBirthKey]),
        }

        const ssoEmail = row[ssoEmailKey]
        if (ssoEmail && typeof ssoEmail === 'string') {
          student.ssoEmail = normalizeJpEmail(ssoEmail)

          if (!checkIfValidEmail(student.ssoEmail)) {
            // delete invalid email
            student.ssoEmail = undefined

            console.error(
              `row ${i - 1} has invalid email! row:`,
              JSON.stringify(row),
            )
            errRowIdsString += `${i - 1}、`
          }

          // const validatedSsoEmail = student.ssoEmail

          // if (validatedSsoEmail) {
          //   // validated email at the step before
          //   // check if duplicated email in this local, not by API
          //   if (uniqueEmails.has(validatedSsoEmail)) {
          //     // no show toast message at this step
          //     console.error(
          //       `row ${i - 1} has duplicated email! row:`,
          //       JSON.stringify(student),
          //     )

          //     const { schoolGrade, schoolClass, schoolAttendanceNumber } =
          //       student

          //     inputDuplicatedSsoEmails.add(validatedSsoEmail)

          //     if (!isShowedToast) {
          //       toast.error(
          //         `${t(
          //           'ご入力いただいた以下の{生徒}のメールアドレスは既に登録されています。',
          //           {
          //             student: isElementarySchool ? '児童' : '生徒',
          //           },
          //         )}\n　　　　　　${schoolGrade}年${schoolClass}組${schoolAttendanceNumber}番`,
          //       )

          //       isShowedToast = true
          //     }
          //   } else {
          //     ssoEmailStudents.push({
          //       ssoEmail: validatedSsoEmail,
          //       schoolGrade: student.schoolGrade,
          //       schoolClass: student.schoolClass,
          //       schoolAttendanceNumber: student.schoolAttendanceNumber,
          //     })
          //   }

          //   uniqueEmails.add(validatedSsoEmail)
          // }
        }

        if (
          student.yearBirth !== undefined ||
          student.monthBirth !== undefined ||
          student.dayBirth !== undefined
        ) {
          // input birthday
          const messageAboutBirth = () => {
            toast.error(
              t(
                '無効な生年月日が含まれています。確認し、再度登録してください。',
              ),
            )

            toast.error(
              t('行番号{{ids}}に無効なデータがあります', {
                ids: i,
              }),
            )
          }

          if (
            student.yearBirth === undefined ||
            student.monthBirth === undefined ||
            student.dayBirth === undefined
          ) {
            messageAboutBirth()
            return
          } else {
            if (
              student.yearBirth > currentYear ||
              student.yearBirth < 1970 ||
              student.monthBirth > 12 ||
              student.monthBirth < 1 ||
              student.dayBirth > 31 ||
              student.dayBirth < 1
            ) {
              messageAboutBirth()
              return
            }

            const lastDayOfMonthBirth = new Date(
              student.yearBirth,
              student.monthBirth,
              0,
            ).getDate()

            if (student.dayBirth > lastDayOfMonthBirth) {
              // invalid day, greater than last day of month
              messageAboutBirth()
              return
            }
            // else valid birthday
          }
        }
        // else not input birthday, do nothing

        _students.push(student)
      }

      if (errRowIdsString.length > 0) {
        // remove unnecessary comma characters.
        errRowIdsString = errRowIdsString.substring(
          0,
          errRowIdsString.length - 1,
        )

        toast.error(
          t('行番号{{ids}}に無効なデータがあります', {
            ids: errRowIdsString,
          }),
        )

        return
      }

      // // validate sso emails
      // if (inputDuplicatedSsoEmails.size > 0) {
      //   setMainErrMess(t('重複したメールアドレスを確認して修正してください。'))
      //   // Set the duplicated email to highlight in the table
      //   setDuplicatedSsoEmails(inputDuplicatedSsoEmails)
      //   return false
      // }

      // if (ssoEmailStudents.length > 0) {
      //   setIsLoading(true)

      //   try {
      //     await axios.post<{
      //       isValid: boolean
      //     }>(
      //       `${process.env.REACT_APP_REST_API_URL}/alpha/v1/school/validate-sso-emails`,
      //       {
      //         students: ssoEmailStudents,
      //       },
      //     )
      //     // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      //   } catch (err: any) {
      //     const errRes = err.response as {
      //       data: {
      //         error?: string
      //         duplicatedKid?: IBaseStudent
      //         duplicateEmail?: string
      //       }
      //     }
      //     console.error('Error code uploading students:', errRes?.data?.error)

      //     // Let the interceptor handle the error message
      //     if (
      //       // errRes?.status === 409 &&
      //       errRes?.data?.error === 'SSO_EMAIL_ALREADY_EXISTS' &&
      //       errRes.data.duplicatedKid
      //     ) {
      //       setMainErrMess(
      //         t('重複したメールアドレスを確認して修正してください。'),
      //       )

      //       const { schoolAttendanceNumber, schoolClass, schoolGrade } =
      //         errRes.data.duplicatedKid

      //       toast.error(
      //         `${t(
      //           'ご入力いただいた以下の{生徒}のメールアドレスは既に登録されています。',
      //           {
      //             student: isElementarySchool ? '児童' : '生徒',
      //           },
      //         )}\n　　　　　　${schoolGrade}年${schoolClass}組${schoolAttendanceNumber}番`,
      //       )

      //       console.error(
      //         'errRes.data.duplicateEmail: ',
      //         errRes.data.duplicateEmail,
      //       )

      //       // Set the duplicated email to highlight in the table
      //       if (errRes.data.duplicateEmail) {
      //         setDuplicatedSsoEmails(new Set([errRes.data.duplicateEmail]))
      //       }
      //     }
      //     setIsLoading(false)

      //     return false
      //   }
      //   setIsLoading(false)
      // }

      _students.sort((a, b) => {
        if (a.schoolGrade !== b.schoolGrade)
          return a.schoolGrade - b.schoolGrade

        if (a.schoolClass !== b.schoolClass)
          return a.schoolClass - b.schoolClass

        return a.schoolAttendanceNumber - b.schoolAttendanceNumber
      })

      setCreatedStudents(_students)
      setUploadFile(file)

      // // validate SSO emails, after step 3
      // const uniqueEmails = new Set<string>()
      // const inputDuplicatedSsoEmails = new Set<string>()
      // let isShowedToast = false
      // let isSsoEmailsValid = true

      // const ssoEmailStudents: ({
      //   ssoEmail: string
      // } & IBaseStudent)[] = []

      // for (let i = 1; i <= createdStudents.length; i++) {
      //   const student = createdStudents[i - 1]
      //   const ssoEmail = student.ssoEmail

      //   if (ssoEmail) {
      //     // validated at the step before
      //     if (uniqueEmails.has(ssoEmail)) {
      //       // no show toast message at this step
      //       console.error(
      //         `row ${i - 1} has duplicated email! row:`,
      //         JSON.stringify(student),
      //       )

      //       const { schoolGrade, schoolClass, schoolAttendanceNumber } = student

      //       inputDuplicatedSsoEmails.add(ssoEmail)

      //       if (!isShowedToast) {
      //         // toast.error(
      //         //   `${t(
      //         //     'ご入力いただいた以下の{生徒}のメールアドレスは既に登録されています。',
      //         //     {
      //         //       student: isElementarySchool ? '児童' : '生徒',
      //         //     },
      //         //   )}\n　　　　　　${schoolGrade}年${schoolClass}組${schoolAttendanceNumber}番`,
      //         // )

      //         setDuplicatedSsoEmailStudentInfo({
      //           schoolGrade,
      //           schoolClass,
      //           schoolAttendanceNumber,
      //         })

      //         isShowedToast = true
      //         isSsoEmailsValid = false
      //       }

      //       setMainErrMess(
      //         t('重複したメールアドレスを確認して修正してください。'),
      //       )
      //     } else {
      //       ssoEmailStudents.push({
      //         ssoEmail,
      //         schoolGrade: student.schoolGrade,
      //         schoolClass: student.schoolClass,
      //         schoolAttendanceNumber: student.schoolAttendanceNumber,
      //       })
      //     }

      //     uniqueEmails.add(ssoEmail)
      //   }
      // }

      // if (inputDuplicatedSsoEmails.size > 0) {
      //   setMainErrMess(t('重複したメールアドレスを確認して修正してください。'))
      //   setDuplicatedSsoEmails(inputDuplicatedSsoEmails)
      // }

      // if (ssoEmailStudents.length > 0 && isSsoEmailsValid) {
      //   setIsLoading(true)

      //   try {
      //     await axios.post<{
      //       isValid: boolean
      //     }>(
      //       `${process.env.REACT_APP_REST_API_URL}/alpha/v1/school/validate-sso-emails`,
      //       {
      //         students: ssoEmailStudents,
      //       },
      //     )
      //     // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      //   } catch (err: any) {
      //     isSsoEmailsValid = false
      //     const errRes = err.response as {
      //       data: {
      //         error?: string
      //         duplicatedKid?: IBaseStudent
      //         duplicateEmail?: string
      //       }
      //     }
      //     console.error('Error code uploading students:', errRes?.data?.error)

      //     // Let the interceptor handle the error message
      //     if (
      //       // errRes?.status === 409 &&
      //       errRes?.data?.error === 'SSO_EMAIL_ALREADY_EXISTS' &&
      //       errRes.data.duplicatedKid
      //     ) {
      //       setMainErrMess(
      //         t('重複したメールアドレスを確認して修正してください。'),
      //       )

      //       const { schoolAttendanceNumber, schoolClass, schoolGrade } =
      //         errRes.data.duplicatedKid

      //       // toast.error(
      //       //   `${t(
      //       //     'ご入力いただいた以下の{生徒}のメールアドレスは既に登録されています。',
      //       //     {
      //       //       student: isElementarySchool ? '児童' : '生徒',
      //       //     },
      //       //   )}\n　　　　　　${schoolGrade}年${schoolClass}組${schoolAttendanceNumber}番`,
      //       // )

      //       setDuplicatedSsoEmailStudentInfo({
      //         schoolGrade,
      //         schoolClass,
      //         schoolAttendanceNumber,
      //       })

      //       console.error(
      //         'errRes.data.duplicateEmail: ',
      //         errRes.data.duplicateEmail,
      //       )

      //       // Set the duplicated email to highlight in the table
      //       if (errRes.data.duplicateEmail) {
      //         setDuplicatedSsoEmails(new Set([errRes.data.duplicateEmail]))
      //       }
      //     }
      //     setIsLoading(false)

      //     return false
      //   }
      //   setIsLoading(false)
      // }

      // if (isSsoEmailsValid) {
      //   // reset error message shower
      //   if (duplicatedSsoEmails) {
      //     setDuplicatedSsoEmails(undefined)
      //   }

      //   if (duplicatedSsoEmailStudentInfo) {
      //     setDuplicatedSsoEmailStudentInfo(undefined)
      //   }

      //   if (mainErrMess) {
      //     setMainErrMess(false)
      //   }
      // }
    }
  }

  const tableColumnsWithHighlight = useMemo(() => {
    const columns = tableColumns(t, isUsingJp)
    return columns.map((col) => {
      if (col && col.dataIndex === 'ssoEmail') {
        return {
          ...col,
          onCell: (record: ICreatedStudents) => ({
            className:
              record.ssoEmail && duplicatedSsoEmails?.has(record.ssoEmail)
                ? 'bg-red-100 hover:bg-red-100-f'
                : '',
          }),
        }
      }
      return col
    })
  }, [t, isUsingJp, duplicatedSsoEmails])

  const steps = [
    {
      title: t('①登録用Excelファイルをダウンロード'),
      content: (
        <div className="steps-content flex flex-col items-center justify-center">
          <div className="flex items-center justify-center mt-4">
            <div className="border border-primary rounded-5px w-10 h-8 -mr-2">
              <DownloadOutlined
                className="text-2xl pl-1 pt-0.5"
                onClick={() => downloadTemplate(isUsingJp, isElementarySchool)}
              />
            </div>
            <Button
              type="primary"
              onClick={() => downloadTemplate(isUsingJp, isElementarySchool)}
            >
              {t('登録用Excelファイルをダウンロード')}
            </Button>
          </div>

          <div className="font-bold mt-1">
            ※{t('SSO認証にも対応しています')}
          </div>
        </div>
      ),
    },
    {
      title: t('②記入例'),
      content: (
        <div className="flex flex-col items-center justify-between">
          <img
            className="mb-4"
            width={800}
            src={isUsingJp ? '/students_demo_ja.webp' : '/students_demo_en.png'}
            alt=""
          />

          <Checkbox
            className="font-black"
            checked={confirmed1}
            onChange={(e) => setConfirmed1(e.target.checked)}
          >
            {t('記入例を確認しましたか？')}
          </Checkbox>
        </div>
      ),
    },
    {
      title: t('③生徒情報をアップロード'),
      content: (
        <div className="steps-content flex flex-col items-center justify-center">
          <div className="h-14 border p-3 border-warn">
            <WarningOutlined className="text-3xl warn-icon" />
            <Text type="danger" className="font-black m-2">
              {t('①でダウンロードしたファイルをアップロードしてください。')}
            </Text>
          </div>

          <div className="flex mt-6">
            <Upload
              multiple={false}
              name="logo"
              listType="text"
              maxCount={1}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              beforeUpload={(file) => {
                parseXlsx(file)
                return false
              }}
              onRemove={() => {
                setCreatedStudents([])
              }}
            >
              <div className="flex">
                <div className="border border-primary rounded-5px w-8 h-8 -mr-2">
                  <UploadOutlined className="text-2xl" />
                </div>
                <Button type="primary">{t('生徒情報をアップロード')}</Button>
              </div>
            </Upload>
          </div>

          <Text className="mb-36 mt-2">{t('XLSXファイル式')}</Text>

          <Checkbox
            className="font-black"
            checked={confirmed2}
            onChange={(e) => setConfirmed2(e.target.checked)}
          >
            {t('生徒情報を正しく記入しましたか？')}
          </Checkbox>
        </div>
      ),
    },
    {
      title: t('④確認'),
      content: (
        <div className="steps-content flex flex-col items-center justify-center">
          {mainErrMess ? (
            <div className="text-red-600 bg-red-50 p-4 rounded-10px mb-4">
              {mainErrMess}
            </div>
          ) : null}

          <div className="mb-5 w-full flex justify-start">
            <Select
              size="small"
              className="w-30 rounded-5px"
              placeholder={t('表示数')}
              value={pageSize}
              dropdownAlign={{
                offset: [0, -2],
              }}
              onChange={changePageSize}
            >
              {displayNumbers.map((d) => (
                <Option className="option-small" value={d.value} key={d.text}>
                  {t('件/ページ', { rows: d.value })}
                </Option>
              ))}
            </Select>
          </div>

          <Table
            columns={tableColumnsWithHighlight as ColumnsType<ICreatedStudents>}
            dataSource={createdStudents}
            rowKey={(record) =>
              `${record.schoolGrade}_${record.schoolClass}_${record.schoolAttendanceNumber}`
            }
            size="small"
            style={{ minWidth: 900 }}
            className="mb-4"
            rowClassName="font-bold text-black"
            bordered={true}
            pagination={{
              hideOnSinglePage: true,
              showSizeChanger: false,
              defaultPageSize: 50,
              pageSize,
              position: ['bottomCenter'],
              total: createdStudents.length,
            }}
          />

          <Checkbox
            className="font-black"
            checked={confirmed3}
            onChange={(e) => setConfirmed3(e.target.checked)}
            disabled={createdStudents.length === 0}
          >
            {t('記入したデータは正しいでしょうか？')}
          </Checkbox>
        </div>
      ),
    },
  ]

  return (
    <Dashboard
      selectedMenu={2}
      navbar={t(isElementarySchool ? '児童情報の登録' : '生徒情報の登録')}
    >
      {!registerComplete ? (
        <>
          <div className="flex justify-center">
            <div className="mt-16 min-w-680px">
              <Steps
                labelPlacement="vertical"
                size="default"
                current={currentStepIdx}
              >
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>

              <div className="steps-content">
                {steps[currentStepIdx].content}
              </div>

              <div className="steps-action text-center">
                {currentStepIdx > 0 && (
                  <Button
                    type="primary"
                    className="h-8 w-24 mx-2"
                    onClick={() => prev()}
                  >
                    {t('戻る')}
                  </Button>
                )}

                {currentStepIdx < steps.length - 1 &&
                  currentStepIdx !== steps.length - 2 && (
                    <Button
                      type="primary"
                      className="h-8 w-24"
                      loading={uploading}
                      onClick={() => next()}
                      disabled={nextBtnDisabled}
                    >
                      {t('次へ')}
                    </Button>
                  )}

                {currentStepIdx === steps.length - 2 && (
                  <Button
                    type="primary"
                    className="h-8 w-24"
                    loading={uploading}
                    onClick={() => nextToStep4()}
                    disabled={nextBtnDisabled}
                  >
                    {t('次へ')}
                  </Button>
                )}

                {currentStepIdx === steps.length - 1 && (
                  <Button
                    type="primary"
                    className="h-8 w-24"
                    loading={uploading}
                    onClick={() => register()}
                    disabled={nextBtnDisabled || !!mainErrMess}
                  >
                    {t('登録')}
                  </Button>
                )}
              </div>
            </div>
          </div>

          <ScreenLoading isLoading={isLoading} />
        </>
      ) : (
        <CompletionLayout
          message={t('登録完了')}
          button={
            <Button
              type="primary"
              onClick={() => history.push('/school_year_info')}
            >
              {t('ログイン管理を見にいく')}
            </Button>
          }
        />
      )}
    </Dashboard>
  )
}

export default StudentsBatchUploadPage
