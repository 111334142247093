import QRCode from 'qrcode.react'
import { useTranslation } from 'react-i18next'
import { getFullName } from '~/school-utils/name'
import isJapanese from '~/utils/isJapanese'

export const PrintingFor1 = ({
  attendanceNumber,
  familyName,
  givenName,
  studentInvitationCode,
  schoolGrade,
  schoolClass,
  code,
  isUsingJpRuby,
}: {
  attendanceNumber: number
  familyName: string | null | undefined
  givenName: string | null | undefined
  studentInvitationCode: string | null | undefined
  schoolGrade: number
  schoolClass: number
  code: string
  isUsingJpRuby: boolean
}) => {
  const { t, i18n } = useTranslation()

  const isUsingJp = isJapanese(i18n)
  const value = `${code}${attendanceNumber}${
    studentInvitationCode ? `_${studentInvitationCode}` : ''
  }`

  return (
    <div className="flex justify-center items-center relative bg-white print-page-for-1">
      <div className="flex flex-col space-y-2 items-center">
        <div className="flex flex-col items-center text-black">
          {isUsingJpRuby ? (
            <>
              <div>
                サインイン
                <ruby>
                  <span>画面</span>
                  <rt>がめん</rt>
                </ruby>
                からQRコードを
              </div>

              <div>
                <ruby>
                  <span>読</span>
                  <rt>よ</rt>
                </ruby>
                み
                <ruby>
                  <span>取</span>
                  <rt>と</rt>
                </ruby>
                ってください
              </div>
            </>
          ) : (
            <>
              <div>{t('サインイン画面からQRコードを')}</div>
              <div>{t('読み取ってください')}</div>
            </>
          )}
        </div>

        <QRCode value={value} />
        <div className="text-sm">{value}</div>

        <div className="text-lg">
          {`${t('g年生', {
            count: schoolGrade,
            ordinal: !isUsingJp,
          })} ${t('個別組', {
            count: schoolClass,
            ordinal: !isUsingJp,
          })} ${t('n番', { num: attendanceNumber })}`}
        </div>

        <div>{`${t('名前')}${t('：')}${getFullName(
          familyName,
          givenName,
          isUsingJp,
        )}`}</div>
      </div>
    </div>
  )
}
