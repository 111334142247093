import { ITeacher } from '~/types/api/alpha/teachers'
import Api from '~/utils/api'
import {
  LOCAL_STORAGE_SCHOOL_USER_KEY,
  SCHOOL_VERSION_CHECK_KEY,
  VERSION_CHECKING_INTERVAL,
} from './constants'
import { getObjFromLocalStorage } from './storages'

export const checkSchoolVersion = async () => {
  const lastCheck = localStorage.getItem(SCHOOL_VERSION_CHECK_KEY)
  if (!lastCheck) return
  console.log('checkSchoolVersion - lastCheck:', lastCheck)
  const now = new Date().getTime()

  // Check if we need to perform the daily check
  if (now - parseInt(lastCheck) > VERSION_CHECKING_INTERVAL) {
    const { data } = await Api.get<{
      ver: string
    }>('/school-ver')

    localStorage.setItem(SCHOOL_VERSION_CHECK_KEY, now.toString())

    // get teacher data from local storage / global store state
    const teacher = getObjFromLocalStorage<{
      state: { teacher: ITeacher }
      version: number
    }>(LOCAL_STORAGE_SCHOOL_USER_KEY)?.state?.teacher

    // If version mismatch, reload the app
    if (teacher && data.ver !== teacher.version) {
      window.location.reload()
    }
  }
}
