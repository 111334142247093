import {
  AuthProvider,
  Client,
  getApolloClient,
  login,
  offlineLink,
} from '@alpha/core'
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { BrowserRouter as Router, Switch } from 'react-router-dom'

import { ConfigProvider } from 'antd'
import en_US from 'antd/lib/locale/en_US'
import ja_JP from 'antd/lib/locale/ja_JP'

import AdvisePage from './components/pages/AdvisePage'
// import DateOfBirthPage from './components/pages/DateOfBirthPage'
import ExerciseContentPage from './components/pages/ExerciseContentPage'
import ExerciseProgramPage from './components/pages/ExerciseProgramPage'
import ExercisesPage from './components/pages/ExercisesPage'
import HomePage from './components/pages/HomePage'
import InformationPage from './components/pages/InformationPage'
import InputPage from './components/pages/InputPage'
import LogoutPage from './components/pages/LogoutPage'
import MoviePage from './components/pages/MoviePage'
import QuestionnaireResultPage from './components/pages/QuestionnaireResultPage'
import QuestionnaireUpload from './components/pages/QuestionnaireUpload'
import QuestionnaireInputPage from './components/pages/QuestionnarieInputPage'
import RegisterUserPage from './components/pages/RegisterUserPage'
import ReportTestPage from './components/pages/ReportTestPage'
import ResultPage from './components/pages/ResultPage'
import SchoolYearInfoPage from './components/pages/SchoolYearInfoPage'
import Setting from './components/pages/Setting'
import SignInPage from './components/pages/SignInPage'
import StudentsBatchUploadPage from './components/pages/StudentsBatchUploadPage'
import StudentsPage from './components/pages/StudentsPage'
import TestInputPage from './components/pages/TestInputPage'
import TestResultPage from './components/pages/TestResultPage'
import TestResultPdfUpload from './components/pages/TestResultPdfUpload'
import TestResultUpload from './components/pages/TestResultUpload'
import UploadExcelPage from './components/pages/UploadExcelPage'
import { PublicRoute } from './components/routes/PublicRoute'
import RedirectRoute from './components/routes/RedirectRoute'
import { useIsOnlineStore } from './context/online'
import { PrivateRoute } from './routes/PrivateRoute'
import { APP_VERSION } from './utils/constants'
import isJapanese from './utils/isJapanese'

// to determine whether new versions is available
console.info(`${APP_VERSION}.1`)

function App() {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>()

  const { setIsOnline } = useIsOnlineStore()
  const { t, i18n } = useTranslation()

  const isUsingJp = isJapanese(i18n)

  useEffect(() => {
    login()

    window.addEventListener('online', () => {
      setIsOnline(true)

      offlineLink
        .sync()
        .then(() => {
          toast.success(t('オンラインになりました。変更を送信しました。'))
        })
        .catch((err) => {
          toast.error(t('キューの送信に失敗しました。'))
          console.error('offlineLink.sync error:', err)
        })
    })

    window.addEventListener('offline', () => {
      setIsOnline(false)
      toast.success(t('オフラインになりました。'))
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    ;(async () => {
      const ac = await getApolloClient(Client.SchoolClient)
      setClient(ac)
    })()
  }, [])

  if (!client) return null

  return (
    <ConfigProvider
      locale={isUsingJp ? ja_JP : en_US}
      autoInsertSpaceInButton={false}
    >
      <ApolloProvider client={client}>
        <AuthProvider>
          <Router>
            <Switch>
              <PrivateRoute path={'/home'} component={HomePage} />
              <PrivateRoute path={'/setting'} component={Setting} />

              <PrivateRoute
                path={'/student_list'}
                component={StudentsBatchUploadPage}
              />
              <PrivateRoute path={'/students'} component={StudentsPage} />

              <PrivateRoute path={'/information'} component={InformationPage} />
              <PrivateRoute path={'/input'} component={InputPage} />
              <PrivateRoute path="/upload-excel" component={UploadExcelPage} />
              <PrivateRoute path={'/result'} component={ResultPage} />
              <PrivateRoute path={'/movie'} component={MoviePage} />

              <PrivateRoute
                path={'/school_year_info'}
                component={SchoolYearInfoPage}
              />

              <PrivateRoute path={'/test'} component={TestInputPage} />
              <PrivateRoute path={'/test_result'} component={TestResultPage} />
              <PrivateRoute
                path={'/test_result_upload'}
                component={TestResultUpload}
              />
              <PrivateRoute
                path={'/test_result_pdf_upload'}
                component={TestResultPdfUpload}
              />
              <PrivateRoute
                path={'/questionnaire'}
                component={QuestionnaireInputPage}
              />
              <PrivateRoute
                path={'/questionnaire_result'}
                component={QuestionnaireResultPage}
              />
              <PrivateRoute
                path={'/questionnaire_upload'}
                component={QuestionnaireUpload}
              />
              <PrivateRoute path={'/test_report'} component={ReportTestPage} />
              <PrivateRoute path="/logout" component={LogoutPage} />
              <PrivateRoute path="/advise" component={AdvisePage} />
              <PrivateRoute path="/exercises" component={ExercisesPage} />
              <PrivateRoute
                path="/exercise_content"
                component={ExerciseContentPage}
              />
              <PrivateRoute
                path="/exercise_program"
                component={ExerciseProgramPage}
              />
              {/* <PrivateRoute
                path={'/date_of_birth'}
                component={DateOfBirthPage}
              /> */}

              {/* not in menu */}
              {/* <PrivateRoute
                path="/import_question"
                component={ImportQuestion}
              /> */}

              {/* not in menu */}
              {/* <PrivateRoute
                path="/import_question_prefecture"
                component={ImportQuestionPrefecture}
              /> */}

              {/* not in menu */}
              {/* <PrivateRoute
                path="/import_question_junior"
                component={ImportQuestionJunior}
              /> */}

              <RedirectRoute path="/redirecting" />

              <PublicRoute path={'/register'} component={RegisterUserPage} />
              <PublicRoute isSchool path="/" component={SignInPage} />
            </Switch>
          </Router>
        </AuthProvider>
      </ApolloProvider>
    </ConfigProvider>
  )
}

export default App
