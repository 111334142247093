import { LOCAL_STORAGE_AUTH_TOKEN_KEY } from '@alpha/core'
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom'

// Create a namespace on the window object for our global variables
// declare global {
//   interface Window {
//     alphaControlCenter: {
//       publicPopupShowing: boolean
//       setPublicPopupShowing: (value: boolean) => void
//     }
//   }
// }

// // Initialize the global variable if it doesn't exist
// if (typeof window !== 'undefined') {
//   window.alphaControlCenter = window.alphaControlCenter || {
//     publicPopupShowing: true,
//     setPublicPopupShowing: (value: boolean) => {
//       window.alphaControlCenter.publicPopupShowing = value
//       // Dispatch an event so that any component listening can update
//       window.dispatchEvent(
//         new CustomEvent('publicPopupStateChanged', { detail: value }),
//       )
//     },
//   }
// }

// interface PopupProps {
//   children: ReactNode
// }

// const Popup = ({ children }: PopupProps) => {
//   return (
//     <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//       <div
//         className="bg-white rounded-lg shadow-xl flex flex-col p-6 relative"
//         style={{
//           width: '800px',
//           height: '600px',
//         }}
//       >
//         {/* <button
//           type="button"
//           onClick={onClose}
//           className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
//         >
//           ×
//         </button> */}
//         <div className="flex-1 overflow-auto">{children}</div>
//       </div>
//     </div>
//   )
// }

interface PublicRouteProps extends Omit<RouteProps, 'component'> {
  component: React.ComponentType<RouteComponentProps>
  isSchool?: boolean
}

export const PublicRoute = ({
  component: Component,
  ...rest
}: PublicRouteProps) => {
  const authToken = window.localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)

  // Initialize state from global variable
  // const [showPopup, setShowPopup] = useState(
  //   window.alphaControlCenter.publicPopupShowing,
  // )

  // // Listen to changes in the global variable
  // useEffect(() => {
  //   const handleGlobalStateChange = (event: CustomEvent<boolean>) => {
  //     // Here's how you can control the popup from the browser console:
  //     // To hide the popup
  //     // window.alphaControlCenter.setPublicPopupShowing(false);

  //     // // To show the popup again
  //     // window.alphaControlCenter.setPublicPopupShowing(true);

  //     // // To check the current state
  //     // window.alphaControlCenter.publicPopupShowing;
  //     setShowPopup(event.detail)
  //   }

  //   // TypeScript needs us to cast the event
  //   window.addEventListener(
  //     'publicPopupStateChanged',
  //     handleGlobalStateChange as EventListener,
  //   )

  //   return () => {
  //     window.removeEventListener(
  //       'publicPopupStateChanged',
  //       handleGlobalStateChange as EventListener,
  //     )
  //   }
  // }, [])

  // // Update global state when local state changes
  // useEffect(() => {
  //   window.alphaControlCenter.publicPopupShowing = showPopup
  // }, [showPopup])

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!authToken) {
          return (
            <>
              <Component {...props} />
              {/*{showPopup && (
                <Popup>
                  <div className="text-center">
                    <h2 className="text-2xl font-bold mb-16 mt-16">
                      システムメンテナンスのお知らせ
                    </h2>

                    <h3 className="mb-4 text-2xl">
                      年度切り替え作業のため、システムを停止しています。
                      <br />
                      再開は<span className="font-bold">4月1日</span>
                      を予定しています。
                    </h3>

                    <h3 className="mb-4 text-2xl">
                      来年度引き続きご利用される場合は、
                      <br />
                      <span className="font-bold">4月1日</span>
                      以降に届く招待コードでログインしてください。
                    </h3>

                    <h3 className="mb-4 text-2xl">
                      ご不便をおかけいたしますが、
                      <br />
                      ご理解とご協力をお願い申し上げます。
                    </h3>

                    <h3 className="mt-12 text-2xl">
                      お問い合わせ先
                      <br />
                      <span className="font-bold">
                        alpha@pestalozzi-tech.com
                      </span>
                    </h3>

                    {/*                     
                    <button
                      type="button"
                      className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
                      onClick={() => setShowPopup(false)}
                    >
                      Got it!
                    </button>
                  </div>
                </Popup>
              )}*/}
            </>
          )
        }
        return <Redirect to="/home" />
      }}
    />
  )
}
