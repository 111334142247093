export const schoolWebRouteNames = [
  'home',
  'setting',
  'student_list',
  'students',
  'information',
  'input',
  'upload-excel',
  'result',
  'movie',
  'school_year_info',
  'test',
  'test_result',
  'test_result_upload',
  'test_result_pdf_upload',
  'questionnaire',
  'questionnaire_result',
  'questionnaire_upload',
  'test_report',
  'logout',
  'advise',
  'exercises',
  'exercise_content',
  'exercise_program',
] as const
